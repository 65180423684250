export const initialState = {
  Controls: [],
  InputState: [],
  HoverData: {
    frameId: '',
    isHovered: false,
    id: 0,
    name: "text",
  },
  EditState: {
    isEdit: false,
    currentTab: "profile",
    id: 0,
  },
  Preview: false,
  FrameData: [],
  selectedFrame: {},
  isSaving: false,
  frameLength: 0,
  savingTxt: "Saving ...",
  passObj: {},
  ControlsDum: [],
  dragStartFrom: "",
  loanCount: null,
  showInfoIcon: false,
  selectedLayoutId: "",
  layoutName: "",
  controlsNotDraggable: false,
  infoIconMessage: `Loan already associated with this layout. So, You can't make any changes in this layout`,
  activeFormGroup: {},
  formGroupChildren: [],
  activeFormGroupPreview: {},
  formGroupChildrenPreview: [],
  payloadArr: [],
  layoutUniqueId: ""
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        InputState: [action.item],
      };

    case "SET_CONTROL":
      return {
        ...state,
        Controls: action.item,
      };
    case "SET_HOVER":
      return {
        ...state,
        HoverData: action.item,
      };
    case "SET_EDIT":
      return {
        ...state,
        EditState: action.item,
      };
    case "SET_PREVIEW":
      return {
        ...state,
        Preview: action.item,
      };
    case "GET_FRAME":
      return {
        ...state,
        FrameData: action.item,
      };
    case "REMOVE_INPUT":
      const removeArr = state.Controls.filter((_, index) => {
        return index !== action.id;
      });
      return {
        ...state,
        Controls: removeArr,
      };
    case "SELECTED_FRAME":
      return {
        ...state,
        selectedFrame: action.item,
      };

    case "RESET_CONTROLS":
      return {
        ...state,
        Controls: [],
      };

    case "SET_SAVE":
      return {
        ...state,
        isSaving: action.item,
      };

    case "FRAME_LENGTH":
      return {
        ...state,
        frameLength: action.item,
      };

    case "SET_SAVINGTXT":
      return {
        ...state,
        savingTxt: action.payload,
      };
    case "PASS_OBJECT":
      return {
        ...state,
        passObj: action.item,
      };

    case "SET_CONTROLDUM":
      return {
        ...state,
        ControlsDum: action.item,
      };

    case "SET_DRAGSTARTFROM":
      return {
        ...state,
        dragStartFrom: action.payload,
      };

    case "SET_LOANCOUNT":
      return {
        ...state,
        loanCount: action.payload,
      };

    case "SET_SHOWINFOICON":
      return {
        ...state,
        showInfoIcon: action.payload,
      };

    case "SET_INFOICON_MESSAGE":
      return {
        ...state,
        infoIconMessage: action.payload,
      };

    case "RESET_INFOICON_MESSAGE":
      return {
        ...state,
        infoIconMessage: `Loan already associated with this layout. So, You can't make any changes in this layout`,
      };

    case "SET_SELECTEDLAYOUT":
      return {
        ...state,
        selectedLayoutId: action.payload,
      };

    case "SET_LAYOUTNAME":
      return {
        ...state,
        layoutName: action.payload,
      };

    case "SET_CONTROLS_NOT_DRAGGABLE":
      return {
        ...state,
        controlsNotDraggable: action.payload,
      };

    case "SET_ACTIVE_FORMGROUP":
      return {
        ...state,
        activeFormGroup: action.payload,
      };

    case "SET_FORMGROUP_CHILDREN": 
      return {
      ...state,
      formGroupChildren: action.payload
    }

    case "SET_ACTIVE_FORMGROUP_PREVIEW":
      return {
        ...state,
        activeFormGroupPreview: action.payload,
      };

    case "SET_FORMGROUP_CHILDREN_PREVIEW": 
      return {
      ...state,
      formGroupChildrenPreview: action.payload
    }

    case "SET_PAYLOAD_ARR": 
      return {
      ...state,
      payloadArr: [...action.payload]
    }

    case "SET_LAYOUT_UNIQUEID": 
      return {
      ...state,
      layoutUniqueId: action.payload,
    }

    default:
      return state;
  }
};

export default reducer;
