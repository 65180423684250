import React, { useEffect, useState } from "react";
import AddcircleIcon from "../../assets/images/addcircle_icon.svg";
import EyetrackingIcon from "../../assets/images/eye_tracking_icon.svg";
import EyetrackingIconWhite from "../../assets/images/eye_tracking_icon_white.svg";
import AssignToIcon from "../../assets/images/assignTo.svg";
import AddCircleoutline from "../../assets/images/addCircleoutline.svg";
import LayoutImg from "../../assets/images/layout_icon.svg";
import LayoutImgWhite from "../../assets/images/layout_icon_white.svg";
import WorkflowIcon from "../../assets/images/workflow_icon.svg";
import { Modal } from "react-bootstrap";
import { useStateValue } from "../../StateProvider/StateProvider";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import SearchInput from "../../CommonComponent/searchInput/autoCompleteInputComponent";
import { Get, Post } from "../../_common/httpService";
import CloudDoneDark from '../../assets/images/cloud-check-dark.svg'
import CloudDoneWhite from '../../assets/images/cloud-check-white.svg'
import CloudUpDark from '../../assets/images/cloud-up-dark.svg'
import CloudUpWhite from '../../assets/images/cloud-up-white.svg'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
const LayoutWorkflowMapping = () => {
  const [{ Preview, selectedLayoutId }, dispatch] = useStateValue();
  const [Addlayoutshow, setAddlayoutShow] = useState(false);
  const [customdropdownOne, setCustomdropdownone] = useState(null);
  const [customdropdownTwo, setCustomdropdowntwo] = useState(null);
  const [isdefault, setDefault] = useState({});
  const [workflowList, setWorkflowList] = useState([]);
  const [layout, setLayout] = useState([]);
  const [persona, setPersona] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [existLayName, setExistLayName] = useState({isShow: false, message: ""});
  const [btnTxt, setBtnTxt] = useState("Create");
  const [allWorkflows, setAllWorkflows] = useState([]);
  const [cusDDOneMouseEnter, setCusDDOneMouseEnter] = useState(false);
  const [cusDDTwoMouseEnter, setCusDDTwoMouseEnter] = useState(false);
  const [AddWorkflowShow, setAddWorkflowShow] = useState(false);
  
  const navigate = useNavigate();
  const param = useParams();
  const layId = sessionStorage.getItem("layout_Id");
  const lname = sessionStorage.getItem("lname");
  let persona_id = sessionStorage.getItem("personaId");
  let isDefaultLayout = JSON.parse(sessionStorage.getItem("isDefault"));

  const [selectedLayout, setSelectedLayout] = useState({
    placeholder: lname ? lname : "",
    id: layId ? layId : "",
  });
  const [selectedWorkflow, setSelectedWorkflow] = useState({
    placeholder: "",
    id: "",
  });
  
  const handleAddlayoutClose = () => {
    formik.setErrors({});
    setCustomdropdownone(false);
    setAddlayoutShow(false);
  };
  
  const handleAddWorkflowClose = () => {
    cloneWorkflowFormik.setErrors({});
    setCustomdropdowntwo(false);
    setAddWorkflowShow(false);
  };
  
  const handleAddlayoutShow = () => setAddlayoutShow(true);
  const handleAddWorkflowShow = () => setAddWorkflowShow(true);

  const getAllWorkflows = async () => {
    try {
      let resp = await Get(`workflow/allworkflows/${layId}`);
      if (resp.statusCode === 200) {
        setAllWorkflows(resp.data);
        if (resp.data.length > 0) {
          resp?.data?.map((ele) => {
            if (ele.active_flag) {
              setSelectedWorkflow({
                placeholder: ele.workflow_name,
                id: ele?.id,
              });
            }
          });
        } else {
          setSelectedWorkflow({
            placeholder: "No workflow found",
            id: "",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getWorkflowList = async () => {
    try {
      let res = await Get(`workflow/all/${persona_id}`);
      setWorkflowList(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLayout();
    let personaId;
    if (param.id) {
      personaId = param.id;
    } else {
      personaId = "01e1fda1-e717-4a97-b25c-71276bf36c7c";
    }
    sessionStorage.setItem("personaId", personaId);
    setPersona(personaId);
    getWorkflowList();
  }, []);

  const getLayout = async (strVal) => {
    let res = await Get(`layout/persona_layout/${persona_id}`);
    if (res.statusCode === 200) {
      setLayout(res.data);
      let bName = `${res.bName}`.toLowerCase().replace(/\s/g, "");
      sessionStorage.setItem("bName", bName);
      // SETTING DEFAULT LAYOUT AS SELECTED IF ONLY CUSTOM LAYOUT SELECTED DOESN'T EXIST.
      if(strVal != "fromCloneLayout") {
        setSelectedLayout({
          id: layId ? layId : res?.data[0]?.id,
          placeholder: lname ? lname : res?.data[0]?.layout_name,
        });
        dispatch({
          type: 'SET_LAYOUT_UNIQUEID',
          payload: res?.data[0]?.id
        })
      }
      var defaultObject = res.data.find((obj) => obj.isdefault === true);
      setDefault(defaultObject);
    }
  };

  const gotoForm = (obj) => {
    sessionStorage.setItem("layout_Id", obj.id);
    sessionStorage.setItem("isDefault", obj.isdefault);
    sessionStorage.setItem("lname", obj.layout_name);
    sessionStorage.setItem("lref", obj.ref_no);
    // navigate("/form-builder");
  };

  const formik = useFormik({
    initialValues: {
      layoutname: "",
      persona_id: 0,
      clone_from: isdefault?.id,
    },
    validate: (values) => {
      const errors = {};

      // CHECK LAYOUT NAME ALREADY EXISTS
      layout?.map((lay) => {
        let layoutName = lay?.layout_name.replaceAll(" ", "").toLowerCase();
        let enteredLayoutName = values.layoutname?.replaceAll(" ","").toLowerCase()
        if(enteredLayoutName == layoutName) {
          errors.layoutname = "Layout name already exist";
        }
      })

      if (!values.layoutname) {
        errors.layoutname = "Layout name should not be empty";
      }

      return errors;
    },
    onSubmit: async (values) => {
      setBtnTxt("Layout Cloning...");
      setDisableBtn(true);
      values.persona_id = persona;
      values.layoutname = values.layoutname.trim().replace(/\s+/g, "");
      if (values.clone_from === undefined) values.clone_from = isdefault?.id;
      try {
        let res = await Post(`layout/create`, values);
        if (res.statusCode === 200) {
          const postBody = {
            cloned_from: cloneWorkflowFormik.values.clone_from,
            workflow_name: `${values.layoutname} Workflow`,
            layoutId: res.data.id,
          };
          let resp = await clone_workflow(postBody);
          if (resp.statusCode === 200) {
            setAllWorkflows(resp?.data);
          }
          // toast.success("Layout Created");***
          gotoForm(res.data);
          dispatch({
            type: "SET_LAYOUTNAME",
            payload: res?.data?.layout_name ? res?.data?.layout_name : "",
          });
          setSelectedLayout({
            placeholder: res?.data?.layout_name,
            id: res?.data?.id,
          });
          dispatch({
            type: "SET_SELECTEDLAYOUT",
            payload: res?.data?.id,
          });
          dispatch({
            type: 'SET_LAYOUT_UNIQUEID',
            payload: res?.data?.id
          })
          handleAddlayoutClose();
          values.layoutname = "";
          getLayout("fromCloneLayout");
          setCustomdropdownone(!customdropdownOne);
          setDisableBtn(false);
          setBtnTxt("Create");
        } else if (res.statusCode === 400) {
          setBtnTxt("Create");
          setExistLayName({ isShow: true, message: res.message });
          setDisableBtn(false);
          setTimeout(() => {
            setExistLayName({ isShow: false, message: "" });
          }, 2000);
        }
      } catch (error) {
        setDisableBtn(false);
        setBtnTxt("Create");
        console.log(error);
      }
    },
  });

  const clone_workflow = async (body) => {
    let resp = await Post("workflow/clone-workflow", body);
    return resp;
  };

  useEffect(() => {
    layId && getAllWorkflows();
  }, [selectedLayoutId, layId]);

  const cloneWorkflowFormik = useFormik({
    initialValues: {
      workflow_name: "",
      clone_from: 1,
    },
    validate: (values) => {
      const errors = {};

      // CHECK WORKFLOW NAME ALREADY EXISTS
      allWorkflows?.map((wf) => {
        const wfName = wf.workflow_name?.replaceAll(" ", "").toLowerCase();
        const enteredWfName = values.workflow_name?.replaceAll(" ", "").toLowerCase();
        if(enteredWfName+"workflow" == wfName) {
          errors.workflow_name = "Workflow name already exist"
        }
      })

      if (!values.workflow_name) {
        errors.workflow_name = "Workflow name is required";
      }

      return errors;
    },
    onSubmit: async (values, { resetForm }) => {
      setBtnTxt("Cloning Workflow...");
      setDisableBtn(true);
      if (!values.clone_from) values.clone_from = workflowList[0]?.workflow_id;

      try {
        // if (isDefaultLayout === false) {
        console.log("FDAFAFA");
        const postBody = {
          cloned_from: values.clone_from,
          workflow_name: values.workflow_name,
          layoutId: layId,
        };
        let res = await clone_workflow(postBody);
        if (res.statusCode === 200) {
          resetForm();
          getAllWorkflows();
          getWorkflowList();

          handleAddWorkflowClose();
          setCustomdropdowntwo(!customdropdownTwo);
          setDisableBtn(false);
          setBtnTxt("Create");
          navigate(`/workflow/${res?.data}`);
        } else if (res.statusCode === 400) {
          setBtnTxt("Create");
          setExistLayName({ isShow: true, message: res.message });
          setDisableBtn(false);
          setTimeout(() => {
            setExistLayName({ isShow: false, message: "" });
          }, 2000);
        } else {
          setBtnTxt("Create");
          setDisableBtn(false);
        }
        // } else {
        //   setBtnTxt("Create");
        //   setExistLayName({
        //     isShow: true,
        //     message: "Cannot create workflow for the Default Layout",
        //   });
        //   setDisableBtn(false);
        //   setTimeout(() => {
        //     setExistLayName({ isShow: false, message: "" });
        //   }, 2000);
        // }
      } catch (error) {
        setDisableBtn(false);
        setBtnTxt("Create");
        console.log(error);
      }
    },
  });

  const handleChangeLayout = (obj) => {
    dispatch({
      type: "SET_SELECTEDLAYOUT",
      payload: obj?.id,
    });
    dispatch({
      type: 'SET_LAYOUT_UNIQUEID',
      payload: obj?.id
    })
    setSelectedLayout({ placeholder: obj?.layout_name, id: obj?.id });
    gotoForm(obj);
    setCustomdropdownone(!customdropdownOne);
    setCusDDOneMouseEnter(false);
  };

  const cursorEnterOne = () => {
    setCusDDOneMouseEnter(true);
  };

  const cursorOutOne = () => {
    setCusDDOneMouseEnter(false);
  };

  const cursorEnterTwo = () => {
    setCusDDTwoMouseEnter(true);
  };

  const cursorOutTwo = () => {
    setCusDDTwoMouseEnter(false);
  };

  return (
    <>
      {!Preview ? (
        <div className="displayFlex AlignitemCenter justifyContent_spaceBetween gap_16">
          {/* <div className="headingText16 fontWeight_500">
                  Drag and Drop Form Builder
                </div> */}

          {/* <div className="InputGroup">
                    
                    <select className="inputDropdown">
                      <option>Select Layout</option>
                      <option>2</option>
                      <option>3</option>
                    </select>
                    <div className="InputGroup-append">
                      <span className="InputGroupText addButton">
                        <img src={AddcircleIcon} alt="AddcircleIcon" />
                      </span>
                    </div>
                  </div> */}

          {/* Layout workflow mapping */}
          <div>
            <p className="fontsize_12 fontWeight_500 mb_zero">
              Selected Layout
            </p>
          </div>
          <div className="positionRelative">
            <input
              onBlur={() => !cusDDOneMouseEnter && setCustomdropdownone(false)}
              readOnly
              className="dropdownSelect_input"
              placeholder={selectedLayout.placeholder}
              onClick={() => setCustomdropdownone(!customdropdownOne)}
            />
            {customdropdownOne && (
              <div
                className="dropdownResult"
                onMouseEnter={cursorEnterOne}
                onMouseLeave={cursorOutOne}
                style={{ maxHeight: "300px" }}
              >
                <div
                  className="dropdownResultItem "
                  onClick={handleAddlayoutShow}
                >
                  <button className="emptyButton displayFlex AlignitemCenter gap_16">
                    <img src={AddCircleoutline} className="width20" />
                    <span className="fontWeight_500 fontsize_12 sunsetOrangeText">
                      {" "}
                      Add New Layout
                    </span>
                  </button>
                </div>
                {/* Layout Modal */}
                <Modal
                  show={Addlayoutshow}
                  onHide={handleAddlayoutClose}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="fontsize16">Add Layout</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="mb_12">
                        <label className="biglabelText mb_8">Layout Name</label>{" "}
                        <span className="requiredText">*</span>
                        <input
                          type="text"
                          transform={(value) => value.trim()}
                          name="layoutname"
                          values={formik.values.layoutname}
                          className="MainInput"
                          placeholder="Enter Layout Name"
                          onChange={formik.handleChange}
                          autoFocus
                          autoComplete="off"
                        />
                        <div
                          style={{ marginTop: "5px" }}
                          className="displayFlex AlignitemCenter justifyContent_spaceBetween"
                        >
                          {formik.errors.layoutname && (
                            <span className="errorText">
                              {formik.errors.layoutname}
                            </span>
                          )}
                          {existLayName.isShow && (
                            <span className="errorText">
                              {existLayName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <label className="biglabelText mb_8">Clone From</label>{" "}
                      <span className="requiredText">*</span>
                      <div className="custom_cloneFrom">
                        <SearchInput
                          name="layout_name"
                          options={layout}
                          styles={{
                            marginBottom: "40px !important",
                            width: "100px",
                          }}
                          // defaultValue={isdefault?.layout_name}
                          placeholder={isdefault?.layout_name}
                          // value={formik.values.layoutname}
                          // value={formik.values.layoutname ? { label: formik.values.layoutname, value: formik.values.layoutname } : null}                    label={'name'}
                          // value={formik.values.layoutname || isdefault?.layout_name}
                          // initialValue={
                          //   formik.values.layoutname || isdefault?.layout_name
                          // }
                          getOptionLabel={(option) => option?.layout_name}
                          getOptionValue={(option) => option?.layout_name}
                          handleChange={(value) => {
                            formik.setValues({
                              ...formik.values,
                              clone_from: value.id,
                              
                            });
                        
                            cloneWorkflowFormik.setValues({
                              ...cloneWorkflowFormik.values,
                              clone_from: value.workflow_id
                            });
                            
                          }}
                        />
                      </div>
                      {formik.errors.clone_from && (
                        <span className="errorText">
                          {formik.errors.clone_from}
                        </span>
                      )}
                      <button
                        disabled={disableBtn}
                        // style={disableBtn ? { opacity: 0.5 } : { opacity: 1 }}
                        type="submit"
                        className="MainButton mt20px"
                      >
                        {btnTxt}
                      </button>
                    </form>
                  </Modal.Body>
                </Modal>
                {layout?.map((lay, index) => (
                  <div
                    onClick={() => handleChangeLayout(lay)}
                    className="dropdownResultItem displayFlex AlignitemCenter gap_16"
                    key={index}
                    style={
                      selectedLayout.id === lay?.id
                        ? { backgroundColor: "#aa4f4f", color: "#fff" }
                        : { backgroundColor: "" }
                    }
                  >
                    <div>
                      <button className="emptyButton">
                        <img
                          src={
                            selectedLayout.id === lay?.id
                              ? LayoutImgWhite
                              : LayoutImg
                          }
                          className="width20"
                        />
                      </button>
                    </div>
                    <div className="width100per displayFlex AlignitemCenter justifyContent_spaceBetween">
                      <div className="fontWeight_500 fontsize_12">
                        {lay?.layout_name}
                      </div>
                      {lay?.isdefault === false && 
                      <div>
                        {
                          selectedLayout.id === lay?.id ? 
                          <OverlayTrigger
        key="right"
                                placement="right"
                                overlay={<Tooltip id={`tooltip-right`} className="custom-tooltip">
          {lay?.active_changes_flag ? "To be published" : "Published"}
        </Tooltip>}>
                               
          <img width={20} height={20} src={lay?.active_changes_flag ? CloudUpWhite : CloudDoneWhite} />
                               
                              </OverlayTrigger>
                          : 
                           <OverlayTrigger
        key="right"
                                placement="right"
                                overlay={<Tooltip id={`tooltip-right`} className="custom-tooltip">
          {lay?.active_changes_flag ? "To be published" : "Published"}
        </Tooltip>}
                              > 
                              <img 
                            width={20} height={20} 
                                    src={lay?.active_changes_flag ? CloudUpDark : CloudDoneDark} /> 
                              </OverlayTrigger>
                        }
                      </div>
                      }
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div>
            <button
              className="emptyButton"
              data-cooltipz-dir="bottom"
              aria-label="Assigned Workflow"
              // onClick={handleAssignShow}
            >
              <img src={AssignToIcon} />
            </button>
          </div>
          <div>
            <p className="fontsize_12 fontWeight_500 mb_zero">
              Linked to Workflow
            </p>
          </div>
          <div className="positionRelative">
            <input
              onBlur={() => !cusDDTwoMouseEnter && setCustomdropdowntwo(false)}
              readOnly
              className="dropdownSelect_input"
              placeholder={selectedWorkflow.placeholder}
              onClick={() => setCustomdropdowntwo(!customdropdownTwo)}
            />
            {customdropdownTwo && (
              <div
                className="dropdownResult"
                onMouseEnter={cursorEnterTwo}
                onMouseLeave={cursorOutTwo}
                style={{ maxHeight: "300px" }}
              >
                {!isDefaultLayout ? <div
                  className="dropdownResultItem"
                  onClick={handleAddWorkflowShow}
                >
                  <button className="emptyButton displayFlex AlignitemCenter gap_12">
                    <img src={AddCircleoutline} className="width20" />
                    <span className="fontWeight_500 fontsize_12 sunsetOrangeText">
                      Add New Workflow
                    </span>
                  </button>
                </div> : <></>}

                {/* Workflow Modal */}
                <Modal
                  show={AddWorkflowShow}
                  onHide={handleAddWorkflowClose}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="fontsize16">
                      Add Workflow
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <form onSubmit={cloneWorkflowFormik.handleSubmit}>
                      <div className="mb_12">
                        <label className="biglabelText mb_8">
                          Workflow Name <span className="requiredText">*</span>
                        </label>
                        <input
                          type="text"
                          transform={(value) => value.trim()}
                          name="workflow_name"
                          values={cloneWorkflowFormik.values.workflow_name}
                          className="MainInput"
                          placeholder="Enter Workflow Name"
                          onChange={cloneWorkflowFormik.handleChange}
                          autoFocus
                          autoComplete="off"
                        />
                        <div
                          style={{ marginTop: "5px" }}
                          className="displayFlex AlignitemCenter justifyContent_spaceBetween"
                        >
                          {cloneWorkflowFormik.errors.workflow_name && (
                            <span className="errorText">
                              {cloneWorkflowFormik.errors.workflow_name}
                            </span>
                          )}
                          {existLayName.isShow && (
                            <span className="errorText">
                              {existLayName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <label className="biglabelText mb_8">
                        Clone From <span className="requiredText">*</span>
                      </label>
                      <div className="custom_cloneFrom">
                        <SearchInput
                          name="workflow_name"
                          options={workflowList}
                          styles={{
                            marginBottom: "40px !important",
                            width: "100px",
                          }}
                          initialValue={workflowList[0]?.workflow_name}
                          // defaultValue={isdefault?.layout_name}
                          placeholder={workflowList[0]?.workflow_name}
                          getOptionLabel={(option) => option?.workflow_name}
                          getOptionValue={(option) => option?.workflow_name}
                          handleChange={(value) => {
                            cloneWorkflowFormik.setValues({
                              ...cloneWorkflowFormik.values,
                              clone_from: value.workflow_id,
                              layoutId: layId,
                            });
                          }}
                        />
                      </div>
                      {cloneWorkflowFormik.errors.clone_from && (
                        <span className="errorText">
                          {cloneWorkflowFormik.errors.clone_from}
                        </span>
                      )}
                      <button
                        disabled={disableBtn}
                        // style={disableBtn ? { opacity: 0.5 } : { opacity: 1 }}
                        type="submit"
                        className="MainButton mt20px"
                      >
                        {btnTxt}
                      </button>
                    </form>
                  </Modal.Body>
                </Modal>
                {allWorkflows?.map((wf, ind) => (
                  <div
                    onClick={() => setCustomdropdowntwo(false)}
                    style={
                      selectedWorkflow.id === wf?.id
                        ? { backgroundColor: "#aa4f4f", color: "#fff" }
                        : { backgroundColor: "" }
                    }
                    className="dropdownResultItem displayFlex AlignitemCenter justifyContent_spaceBetween"
                    key={ind}
                  >
                    <div className="fontWeight_500 fontsize_12">
                      {wf?.workflow_name}
                    </div>
                    <div>
                      <button
                        style={
                          selectedWorkflow.id === wf?.id
                            ? { backgroundColor: "#fff", color: "#aa4f4f" }
                            : { backgroundColor: "#aa4f4f", color: "#fff" }
                        }
                        onClick={() => navigate(`/workflow/${wf?.id}`)}
                        className="smallView"
                      >
                        {/* <img src={selectedWorkflow.id === wf?.id ? EyetrackingIcon : EyetrackingIconWhite} /> */}
                        <img
                          src={
                            selectedWorkflow.id === wf?.id
                              ? EyetrackingIcon
                              : EyetrackingIconWhite
                          }
                        />
                        &nbsp; View
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="headingText16 fontWeight_500">Preview Fields</div>
      )}
    </>
  );
};

export default LayoutWorkflowMapping;
