import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';

const Signature = ({
  width,
  height,
  isDefault,
  defaultLayout,
  editFieldProperties,
  setEditFieldProperties,
  fieldProperties,
  index,
}) => {
  const signRef = useRef(null);
  const handleSubmit = async (e) => {
    // e.preventDefault();
    // if (!signRef.current._sigPad._isEmpty) {
    //   let data = signRef.current.getTrimmedCanvas().toDataURL();
    //   try {
    //     let res = await Post(
    //       `loan/signContract/${loanid}`,
    //       { signature: data },
    //       "admin"
    //     );
    //     if (res.statusCode === 200) {
    //     //   setHtmlData(<></>);
    //     //   setHtmlData(res.data);
    //     //   setEnableSubmitBtn(false);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // } else {
    // }
  };

  const handleClear = () => {
    signRef.current.clear();
    const newEditFieldProperties = { ...editFieldProperties };
    newEditFieldProperties[index.FI].controls[index.CI].originalValue = '';
    newEditFieldProperties[index.FI].controls[index.CI].inputFieldValue = '';
    newEditFieldProperties[index.FI].controls[index.CI].validity.valid = false;
    newEditFieldProperties[index.FI].controls[index.CI].error = fieldProperties[
      index.FI
    ][index.CI].required
      ? `Kindly Provide ${fieldProperties[index.FI][index.CI].labelName}`
      : '';
    setEditFieldProperties(newEditFieldProperties);
  };

  const updateValue = () => {
    const newEditFieldProperties = { ...editFieldProperties };
    const value = signRef.current.getTrimmedCanvas().toDataURL();
    newEditFieldProperties[index.FI].controls[index.CI].originalValue = value;
    newEditFieldProperties[index.FI].controls[index.CI].inputFieldValue = value;
    newEditFieldProperties[index.FI].controls[index.CI].validity.valid = true;
    newEditFieldProperties[index.FI].controls[index.CI].error = '';
    setEditFieldProperties(newEditFieldProperties);
  };

  return (
    <div
      style={{ cursor: 'auto' }}
      draggable={isDefault && defaultLayout ? 'false' : null}
    >
      <SignatureCanvas
        backgroundColor="whitesmoke"
        ref={signRef}
        onEnd={() => updateValue()}
        canvasProps={{ width: width, height: height, draggable: 'false' }}
      />
      <form
        onSubmit={handleSubmit}
        draggable={isDefault && defaultLayout ? 'false' : null}
      >
        <div draggable={isDefault && defaultLayout ? 'false' : null}>
          <button
            className="smallMainButton"
            type={'button'}
            onClick={handleClear}
          >
            Clear
          </button>
          {/* <button className="smallMainButton" type={"submit"} handleClick={handleSubmit}>
            Continue
          </button> */}
        </div>
      </form>
    </div>
  );
};

export default Signature;
