import React, { useState } from "react";
const TextArea = (props) => {
  const { type, id, maxLength, cols, rows, required, labelName, placeholder, isDefault, defaultLayout, activeFlag } =
    props;
    const [typedCount, setTypedCount] = useState(0)
  return (
    <>
      {type === "textarea" ? (
        <>
          <label className="biglabelText" htmlFor={id}
            draggable={isDefault && defaultLayout ? "false" : null}
          >
            {labelName}
          </label>
          <div className="displayFlex flexDirectionCol">
            <textarea
              onChange={(e) => setTypedCount(e.target.value?.length)}
              className="textAreaInput"
              maxLength={maxLength}
              cols={cols}
              name={`textArea${id}`}
              rows={rows}
              placeholder={placeholder}
              required={required}
              draggable={isDefault && defaultLayout ? "false" : null}
              disabled={activeFlag ? false : true}
            />
            <span style={{fontSize: '12px', alignSelf: "flex-end"}}>{typedCount}/{maxLength}</span>
          </div>
        </>
      ) : null}
    </>
  );
};

export default TextArea;
