import "./App.css";
import "./style.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import Preview from "./pages/Preview";
import Thankyou from "./pages/Thankyou";
import Workflow from "./pages/Workflow/Workflow";
import Controls from "./pages/Controls";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Layout />}/> */}
        {/* <Route path="/:id" element={<Layout />} /> */}
        <Route path="/form-builder/:id" element={<Controls />} />
        <Route path="/preview" element={<Preview />} />
        <Route path="/publish" element={<Thankyou />} />
        <Route path="/workflow/:workflowID" element={<Workflow />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
