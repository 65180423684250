import React, { useState, useEffect, useRef } from "react";
import { useStateValue } from "../StateProvider/StateProvider";
import InputField from "../CommonComponent/InputField/InputField";
import TextArea from "../CommonComponent/TextArea.jsx/TextArea";
import Dropdown from "../CommonComponent/dropdown/Dropdown";
import EditAndDelete from "../Components/EditAndDelete/EditDelete";
import "react-toastify/dist/ReactToastify.css";
import Frameselect from "../CommonComponent/messagecontent/frameselect";
import Controlselect from "../CommonComponent/messagecontent/controlselect";
import { Get, Post } from "../_common/httpService";
import Signature from "../CommonComponent/Signature/Signature";
import infoIcon from "../assets/images/infoIcon.svg";
import BankImage from "../assets/images/bank.png";
import { Modal } from "react-bootstrap";
import { updateRadioControllerItemName } from "../Components/updateRadioControllerItemName";

const Frame = () => {
  const [input, setInput] = useState([]);
  const [isFrameSelected, setIsFrameSelected] = useState(false);
  const [
    {
      InputState,
      HoverData,
      EditState,
      Controls,
      selectedFrame,
      frameLength,
      payloadArr,
      passObj,
      ControlsDum,
      dragStartFrom,
      loanCount,
      showInfoIcon,
      infoIconMessage,
      activeFormGroup,
      formGroupChildren,
      selectedLayoutId,
      nextControlIndex
    },
    dispatch,
  ] = useStateValue();
  const [isframe] = useState(true);
  // const [isdrag, setIsdrag] = useState(false);
  const defaultLayout = JSON.parse(sessionStorage.getItem("isDefault"));
  let layoutId = sessionStorage.getItem("layout_Id");
  const [infoTooltip, setInfoTooltip] = useState(false);
  const [selectedFormGroupOption, setSelectedFormGroupOption] = useState({});
  const [showFGAlreadyEnablePopup, setShowFGAlreadyEnablePopup] = useState(false);

  useEffect(() => {
    setInput(Controls);
    Object.keys(selectedFrame).length >= 0
      ? setIsFrameSelected(true)
      : setIsFrameSelected(false);
    if (Object.keys(selectedFormGroupOption).length > 0) {
      let filteredFormGroupArr = input?.filter(
        (ele) => ele.control_id == activeFormGroup?.control_id
      );
      let formGroupChildrenArr = filteredFormGroupArr[0]?.children?.filter(
        (ele) => ele.group_by === activeFormGroup?.uuid
      );
      dispatch({
        type: "SET_FORMGROUP_CHILDREN",
        payload: formGroupChildrenArr,
      });
    }
  }, [
    Controls,
    InputState,
    input,
    selectedFrame,
    isFrameSelected,
    frameLength,
  ]);

  const updateSelectedFormGroupOption = (obj) => {
    if (Object.keys(selectedFormGroupOption)?.length > 0) {
      if (selectedFormGroupOption?.control_id === obj?.control_id) {
        setSelectedFormGroupOption(obj);
        dispatch({
          type: "SET_ACTIVE_FORMGROUP",
          payload: obj,
        });
        let filteredFormGroupArr = input?.filter(
          (ele) => ele.control_id == obj?.control_id
        );
        let formGroupChildrenArr = filteredFormGroupArr[0]?.children?.filter(
          (ele) => ele.group_by === obj?.uuid
        );
        dispatch({
          type: "SET_FORMGROUP_CHILDREN",
          payload: formGroupChildrenArr,
        });
      } else {
        let fg = document.getElementById(obj.id);
        console.log("fg::: ", fg);
        if (fg) {
          console.log("working");
          fg.checked = false;
        }
        // document.getElementById(obj.id).checked = false;
        setShowFGAlreadyEnablePopup(true)
      }
    } else {
      setSelectedFormGroupOption(obj);
      let filteredFormGroupArr = input?.filter(
        (ele) => ele.control_id == obj?.control_id
      );
      let formGroupChildrenArr = filteredFormGroupArr[0]?.children?.filter(
        (ele) => ele.group_by === obj?.uuid
      );
      dispatch({
        type: "SET_FORMGROUP_CHILDREN",
        payload: formGroupChildrenArr,
      });
      dispatch({
        type: "SET_ACTIVE_FORMGROUP",
        payload: obj,
      });
    }
  };
  // EXIT FORM GROUP
  const handleExitFormGroup = () => {
    let fg = document.getElementById(selectedFormGroupOption?.id);

    if (fg) {
      fg.checked = false;
    }
    setSelectedFormGroupOption({});
    document.getElementById(`${activeFormGroup?.control_id}_formgroup_child`)?.remove();
    dispatch({
      type: "SET_ACTIVE_FORMGROUP",
      payload: {},
    });
    dispatch({
      type: "SET_FORMGROUP_CHILDREN",
      payload: [],
    });
  };

  useEffect(() => {
    Object.keys(activeFormGroup).length > 0 && handleExitFormGroup();
  }, [selectedFrame]);

  const handleHover = (e, controlID, frameId, defaultControl, control_index) => {
    if (EditState.isEdit !== true) {
      let props;
      if (Object.keys(activeFormGroup).length > 0) {
        props = formGroupChildren?.filter(
          (ele) => ele.control_id === controlID
        );
      } else {
        props = Controls.filter((ele) => ele?.control_id === controlID);
      }
      dispatch({
        type: "SET_HOVER",
        item: { isHovered: true, frameId, defaultControl, control_index, id: controlID, props: props[0]?.properties },
      });
    }
  };
  const handleMouseOut = () => {
    if (!EditState.isEdit) {
      dispatch({
        type: "SET_HOVER",
        item: { isHovered: false, id: 0, props: {} },
      });
    }
  };

  //save reference for dragItem and dragOverItem
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  const dragOnItem = useRef(null);
  const dragItemIndex = useRef(null);
  const dragOverItemIndex = useRef(null);

  const dragStart = (event, id) => {
    event.dataTransfer.dropEffect = "move";
    dispatch({
      type: "SET_DRAGSTARTFROM",
      payload: "frame",
    });
    dragItem.current = id;
    // setIsdrag(true);
  };

  const dragEnter = (event, id) => {
    event.dataTransfer.dropEffect = "move";
    dragOverItem.current = id;
  };
  const dragOver = (event, id) => {
    // console.log("dragOver", event)
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
    dragOnItem.current = id;
    let cls = ["col-md-6", "", "textAreaInput"];
    // if (cls.includes(event.target.className)) {
    //   event.target.style.border = "2px dotted lightgreen";
    // }
  };

  function dragLeave(event, id) {
    let cls = ["col-md-6", "mouseOverFrame mb_12", "textAreaInput"];
    if (cls.includes(event.target.className)) {
      event.target.style.border = "";
    }
  }

  //handle sort
  const handleSort = async (event) => {
    if (loanCount === 0) {
      try {
        dispatch({
          type: 'SET_SAVE',
          item: true,
        });
        if (dragOverItem.current && dragOverItem.current !== dragItem.current) {
          let _input;
          if (Object.keys(activeFormGroup).length > 0) {
            _input = [...formGroupChildren];
          } else {
            _input = [...input];
          }
  
          // remove and save the dragged item content
          _input.map((e, i) => {
            if (e.control_id == dragItem.current) {
              dragItemIndex.current = i;
            }
          });
          const draggedItemContent = _input.splice(dragItemIndex.current, 1)[0];
          // correct
          _input.map((e, i) =>
            e?.control_id === dragOverItem.current
              ? (dragOverItemIndex.current = i)
              : null
          );
  
          //switch the position
          const dragOverContent = _input.splice(dragOverItemIndex.current, 1)[0];
  
          _input.splice(dragOverItemIndex.current, 0, draggedItemContent);
  
          _input.splice(dragOverItemIndex.current + 1, 0, dragOverContent);
  
          //reset the position ref
          dragItem.current = null;
          dragOverItem.current = null;
          dragItemIndex.current = null;
          dragOverItemIndex.current = null;
  
          //update the actual array
          if (Object.keys(activeFormGroup)?.length > 0) {
            dispatch({
              type: "SET_FORMGROUP_CHILDREN",
              payload: _input,
            });
          } else {
            dispatch({
              type: "SET_CONTROL",
              item: _input,
            });
          }
  
          let updated_arr = _input.map((cdata, i) => ({
            ...cdata,
            control_index: i + 1,
            // control_name: `${selectedFrame.frame_ref}_${cdata.labelName}_${i + 1}`,
            ref_no: cdata?.control_id,
            frame_id: selectedFrame?.frame_ref,
            isformgroup_child:
              Object.keys(selectedFormGroupOption)?.length > 0 ? true : false,
          }));
  
          const resp = await Post(`controls/create`, {
            controls: updated_arr,
          });
          if(resp.statusCode === 200) {
            dispatch({
              type: 'SET_SAVE',
              item: false,
            });
          await updateRadioControllerItemName(resp.data, formGroupChildren, dispatch);
          }
        }
      } catch (error) {
        console.log('error::: ', error);
        dispatch({
          type: 'SET_SAVE',
          item: false,
        });
      }
    } else {
      dispatch({
        type: "SET_SHOWINFOICON",
        payload: true,
      });
    }
  };

  const handlePayloadArr = (e) => {
    e.preventDefault();
    const nextIndex = sessionStorage.getItem("nextIndex");
    sessionStorage.setItem("nextIndex", Number(nextIndex)+1); 
    dispatch({
      type: "SET_PAYLOAD_ARR",
      payload: [...payloadArr, passObj]
    });
  }

  useEffect(() => {
    if(payloadArr?.length > 0) {
      saveControl();
    }
  }, [payloadArr]);

  const saveControl = async (e) => {
    if (!defaultLayout && loanCount === 0) {
      dispatch({
        type: "SET_SAVE",
        item: true,
      });
      let bodyData = payloadArr[0];
      if (Object.keys(selectedFormGroupOption).length === 0) {
        dispatch({
          type: "SET_CONTROL",
          item: [...Controls,  payloadArr[0]],
        });
        // REMOVE THE FIRST OBJECT IN PAYLOAD_ARR
          payloadArr?.shift();
      }
      // INSERTING VALUES INTO PAYLOAD OBJECT FOR FORM GROUP CHILD
      if (Object.keys(selectedFormGroupOption).length > 0) {
        payloadArr[0].isformgroup_child = true;
        payloadArr[0].group_by = selectedFormGroupOption?.uuid;
        payloadArr[0].parent_control_id = selectedFormGroupOption?.control_id;
        dispatch({
          type: "SET_FORMGROUP_CHILDREN",
          payload: [...formGroupChildren, payloadArr[0]],
        });
        // REMOVE THE FIRST OBJECT IN PAYLOAD_ARR
          payloadArr?.shift();
      }
      try {
        let data = await Post(`controls/create`, {
          controls: [bodyData],
        });
        if (data.statusCode === 200) {
          dispatch({
            type: "SET_CONTROL",
            item: data.data,
          });
          dispatch({
            type: "SET_SAVE",
            item: false,
          });
          await updateRadioControllerItemName(data.data, formGroupChildren, dispatch);
          dispatch({type: 'SET_DRAGSTARTFROM', payload: ''});
        } else if (data.statusCode === 400) {
          dispatch({
            type: "SET_SHOWINFOICON",
            payload: true,
          });
          dispatch({
            type: "SET_INFOICON_MESSAGE",
            payload: data?.message,
          });
          dispatch({
            type: "SET_SAVE",
            item: false,
          });
          dispatch({
            type: 'PASS_OBJECT',
            item: {}
          });
          dispatch({type: 'SET_DRAGSTARTFROM', payload: ''});
          setTimeout(() => {
            dispatch({
              type: "SET_SHOWINFOICON",
              payload: false,
            });
            dispatch({
              type: "RESET_INFOICON_MESSAGE",
            });
          }, 5000);
        } else {

          dispatch({
            type: "SET_SAVE",
            item: false,
          });
          dispatch({
            type: 'PASS_OBJECT',
            item: {}
          })
        }
      } catch (error) {
        dispatch({
          type: 'PASS_OBJECT',
          item: {}
        })
        dispatch({type: 'SET_DRAGSTARTFROM', payload: ''});
        console.log("error::: ", error);
      }
    } else {
      dispatch({
        type: "SET_SHOWINFOICON",
        payload: true,
      });
    }
  };

  const handleDisableFrame = async (frameID) => {
    try {
      if (loanCount === 0) {
        dispatch({
          type: 'SET_SAVE',
          item: true,
        });
        let res = await Get(`frame/toggle_activity/${frameID}/${layoutId}`);
        if (res.statusCode === 200) {
          dispatch({
            type: 'SET_SAVE',
            item: false,
          });
          dispatch({
            type: "FRAME_LENGTH",
            item: res.data.length,
          });
          dispatch({
            type: "GET_FRAME",
            item: res.data,
          });
          res.data?.map((frm) =>
            selectedFrame.frame_id === frm.frame_id
              ? dispatch({
                  type: "SELECTED_FRAME",
                  item: frm,
                })
              : null
          );
          let resp = await Get(`controls/getByFrame/${frameID}`);
          if (resp.statusCode === 200) {
            dispatch({
              type: "RESET_CONTROLS",
              item: [],
            });
            dispatch({
              type: "SET_CONTROL",
              item: resp.data,
            });
          }
        }
      } else {
        dispatch({
          type: "SET_SHOWINFOICON",
          payload: true,
        });
      }
    } catch (error) {
      dispatch({
        type: 'SET_SAVE',
        item: false,
      });
      console.log(error);
    }
  };

  const handleSkip = async (frameId) => {
    try {
      if (loanCount === 0) {
        dispatch({
          type: 'SET_SAVE',
          item: true,
        });
        let res = await Get(`frame/toggle_skip/${frameId}/${layoutId}`);
        if (res.statusCode === 200) {
          dispatch({
            type: 'SET_SAVE',
            item: false,
          });
          dispatch({
            type: "FRAME_LENGTH",
            item: res.data.length,
          });
          dispatch({
            type: "GET_FRAME",
            item: res.data,
          });
          res.data?.map((frm) =>
            selectedFrame.frame_id === frm.frame_id
              ? dispatch({
                  type: "SELECTED_FRAME",
                  item: frm,
                })
              : null
          );
        }
      } else {
        dispatch({
          type: "SET_SHOWINFOICON",
          payload: true,
        });
      }
    } catch (error) {
      dispatch({
        type: 'SET_SAVE',
        item: false,
      });
      console.log(error);
    }
  };

  // REMOVE ALREADY LOAN ASSOCIATED INFOICON INDICATOR WHILE SWITCH ANOTHER LAYOUT
  useEffect(() => {
    showInfoIcon && dispatch({
      type: "SET_SHOWINFOICON",
      payload: false,
    });
    setInfoTooltip(false);
  }, [selectedLayoutId]);

  return (
    <>
    {/* FORM GROUP ALREADY ENABLED ALERT POPUP START */}
    <Modal 
      show={showFGAlreadyEnablePopup} 
      onHide={() => setShowFGAlreadyEnablePopup(false)} 
      className="smallpopup"
    >
        <Modal.Header>
          <Modal.Title>⚠️Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            Form Group already enabled for the <b>{selectedFormGroupOption?.labelName}</b> control
          </div> 
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button className="smallMainButton"
              onClick={() => setShowFGAlreadyEnablePopup(false)}
            >
              OK
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    {/* FORM GROUP ALREADY ENABLED ALERT POPUP END */}

      <div className="displayFlex AlignitemCenter justifyContent_spaceBetween mb_12 frameHeader">
        <div className="displayFlex AlignitemCenter ">
          <h3 className="headingText18 fontWeight_600 pr10">
            {selectedFrame.frame_name}
          </h3>
          {(loanCount > 0 || showInfoIcon) && (
              <div>
                {showInfoIcon && (
                  <>
                    <div className="infoiconBlock iconanimate2"></div>
                    <div
                      className="infoiconBlock2"
                      onClick={() => setInfoTooltip(!infoTooltip)}
                    >
                      <img src={infoIcon} alt="InfoIcon" />
                    </div>
                  </>
                )}
                {infoTooltip && (
                  <div className="infoPopup">{infoIconMessage}</div>
                )}
              </div>
            )}
        </div>

        {Object.keys(selectedFormGroupOption).length > 0 &&
          selectedFormGroupOption?.type === "radio" &&
          selectedFormGroupOption?.isFormGroup && (
            <div style={{ fontSize: "13px" }}>
              <b>Form Group Enabled:</b> Control -{" "}
              {selectedFormGroupOption?.labelName}, selected:{" "}
              {selectedFormGroupOption?.selected}
              <button className="exitBtn" onClick={handleExitFormGroup}>
                Exit Form Group
              </button>
            </div>
          )}

        <div>
          {defaultLayout === false &&
            selectedFrame.isdefault &&
            selectedFrame.frame_name !== "Personal" &&
            selectedFrame.frame_name !== "Financials" && (
              <div className="displayFlex">
                <div>
                  <button className="emptyButton">
                    <div className="toggle-radio">
                      {selectedFrame.frame_name !== "Bank Lookup" && (
                        <>
                          <input
                            type="checkbox"
                            name="default"
                            id="default_Option1"
                            value="Option1"
                            checked={selectedFrame.active_flag ? true : false}
                            readOnly
                            style={{ display: "none" }}
                          />
                          <label
                            htmlFor="default_Option1"
                            onClick={() =>
                              !selectedFrame.active_flag &&
                              handleDisableFrame(selectedFrame.frame_id)
                            }
                          >
                            Enable
                          </label>
                        </>
                      )}

                      <>
                        <input
                          type="checkbox"
                          name="default"
                          id="default_Option2"
                          value="Option2"
                          disabled={!selectedFrame.active_flag ? true : false}
                          checked={selectedFrame.skip_frame ? true : false}
                          readOnly
                          style={{ display: "none" }}
                        />
                        <label
                          htmlFor="default_Option2"
                          onClick={() => handleSkip(selectedFrame.frame_id)}
                        >
                          Skip
                        </label>
                      </>

                      {selectedFrame.frame_name !== "Bank Lookup" && (
                        <>
                          <input
                            type="checkbox"
                            name="default"
                            id="default_Option3"
                            value="Option3"
                            checked={!selectedFrame.active_flag ? true : false}
                            // disabled={!selectedFrame.active_flag ? true : false}
                            readOnly
                            style={{ display: "none" }}
                          />
                          <label
                            htmlFor="default_Option3"
                            onClick={() =>
                              selectedFrame.active_flag &&
                              handleDisableFrame(selectedFrame.frame_id)
                            }
                          >
                            Disable
                          </label>
                        </>
                      )}
                    </div>
                  </button>
                </div>
              </div>
            )}
        </div>
      </div>

      <div
        className={
          isFrameSelected && Controls.length === 0
            ? "dragAndDrop_frame displayFlex AlignitemCenter justifyContent_Center"
            : "dragAndDrop_frame test"
        }
        id={"drag&drop_parent_node"}
        onDragOver={(event) => dragOver(event, "")}
        onDrop={(e) =>
          dragStartFrom === "control" &&
          Object.keys(activeFormGroup).length === 0 &&
          handlePayloadArr(e)
        }
      >
        {isFrameSelected && <Frameselect />}
        {Object.keys(selectedFrame).length > 0 && Controls.length === 0 && (
          <Controlselect />
        )}
        <div className="row">
          {input?.map((elements, index) => (
            <div
              className={
                elements?.properties?.name === "paragraph" ||
                (elements?.properties?.type === "radio" &&
                  elements?.properties?.isFormGroup)
                  ? "col-md-12"
                  : "col-md-6"
              }
              key={index}
              draggable={
                elements?.isdefault && defaultLayout ? "false" : "true"
              }
              style={{
                cursor: elements?.isdefault && defaultLayout ? "auto" : "move",
              }}
              onDragStart={(event) =>
                !defaultLayout &&
                Object.keys(selectedFormGroupOption)?.length === 0
                  ? dragStart(event, elements?.control_id)
                  : null
              }
              onDragEnter={(event) =>
                !defaultLayout &&
                Object.keys(selectedFormGroupOption)?.length === 0
                  ? dragEnter(event, elements?.control_id)
                  : null
              }
              onDragEnd={(e) =>
                dragStartFrom === "frame" &&
                Object.keys(selectedFormGroupOption)?.length === 0 &&
                handleSort(e)
              }
              onDragOver={(event) =>
                !defaultLayout &&
                Object.keys(selectedFormGroupOption)?.length === 0
                  ? dragOver(event, elements?.control_id)
                  : null
              }
              onDragLeave={(event) =>
                !defaultLayout &&
                Object.keys(selectedFormGroupOption)?.length === 0
                  ? dragLeave(event, elements?.control_id)
                  : null
              }
              onDrop={(e) => (e.target.style.border = "")}
            >
              {elements?.properties?.type === "textarea" ? (
                <div
                  draggable={
                    elements?.isdefault && defaultLayout ? "false" : null
                  }
                  className={
                    elements?.control_id === dragItem.current
                      ? "drag mouseOverposition"
                      : "mouseOverFrame mb_12"
                  }
                  style={
                    elements.active_flag ? { opacity: 1 } : { opacity: 0.5 }
                  }
                  onMouseOver={(e) => handleHover(e, elements?.control_id, elements?.frame_id, elements?.isdefault, elements?.control_index)}
                  onMouseOut={handleMouseOut}
                >
                  <TextArea
                    isDefault={elements?.isdefault}
                    defaultLayout={defaultLayout}
                    key={elements?.control_id}
                    id={elements?.control_id}
                    type={elements.properties?.type}
                    labelName={elements?.properties?.labelName}
                    minLength={elements?.properties?.minLength}
                    placeholder={elements?.properties?.placeholder}
                    activeFlag={elements?.active_flag}
                    cols={elements?.properties?.cols}
                    rows={elements?.properties?.rows}
                    maxLength={elements.properties.maxLength}
                  />
                  {defaultLayout === false && (
                    <div
                      className="hoverAction"
                      draggable={
                        elements.isdefault && defaultLayout ? "false" : null
                      }
                    >
                      {HoverData.isHovered &&
                      HoverData.id === elements?.control_id &&
                      HoverData.props?.name === "textarea" ? (
                        <EditAndDelete
                          controlId={elements?.control_id}
                          activeFlag={elements?.active_flag}
                          defaultControl={HoverData?.defaultControl}
                          id={HoverData.id}
                          prop={HoverData.props}
                          frame={isframe}
                        />
                      ) : null}
                    </div>
                  )}
                </div>
              ) : elements?.properties?.name === "banklookup" ? (
                <div
                  draggable={
                    elements?.isdefault && defaultLayout ? "false" : null
                  }
                  className={
                    elements?.control_id === dragItem.current
                      ? "drag mouseOverposition"
                      : "mouseOverFrameBanklookup mb_12"
                  }
                  style={
                    elements?.active_flag ? { opacity: 1 } : { opacity: 0.5 }
                  }
                  onMouseOver={(e) => handleHover(e, elements?.control_id, elements?.frame_id, elements?.isdefault, elements?.control_index)}
                  onMouseOut={handleMouseOut}
                >
                  <div>
                    <img src={BankImage} alt="" />
                    <button style={{ width: "130px" }} className="MainButton">
                      Connect Bank
                    </button>
                  </div>
                </div>
              ) : elements?.properties?.name === "paragraph" ? (
                <div
                  draggable={
                    elements?.isdefault && defaultLayout ? "false" : null
                  }
                  className={
                    elements?.control_id === dragItem.current
                      ? "drag mouseOverposition"
                      : "mouseOverFrame mb_12"
                  }
                  style={
                    elements?.active_flag ? { opacity: 1 } : { opacity: 0.5 }
                  }
                  onMouseOver={(e) => handleHover(e, elements?.control_id, elements?.frame_id, elements?.isdefault, elements?.control_index)}
                  onMouseOut={handleMouseOut}
                >
                  <p className="paragraphControl"
                    style={{
                      fontSize: `${elements?.properties?.fontSize}px`,
                      fontWeight: elements?.properties?.bold
                        ? "bold"
                        : "normal",
                      fontStyle: elements?.properties?.italic
                        ? "italic"
                        : "normal",
                    }}
                    draggable={
                      elements?.isdefault && defaultLayout ? "false" : null
                    }
                  >
                    {elements?.properties?.paragraph}
                  </p>
                  {defaultLayout === false && (
                    <div
                      className="hoverAction"
                      draggable={
                        elements?.isdefault && defaultLayout ? "false" : null
                      }
                    >
                      {HoverData.isHovered &&
                      HoverData.id === elements?.control_id &&
                      HoverData.props?.name === "paragraph" ? (
                        <EditAndDelete
                          controlId={elements?.control_id}
                          activeFlag={elements?.active_flag}
                          defaultControl={HoverData?.defaultControl}
                          id={HoverData.id}
                          prop={HoverData.props}
                          frame={isframe}
                        />
                      ) : null}
                    </div>
                  )}
                </div>
              ) : elements?.properties?.name === "sign" ? (
                <div
                  draggable={
                    elements?.isdefault && defaultLayout ? "false" : null
                  }
                  className={
                    elements?.control_id === dragItem.current
                      ? "drag mouseOverposition"
                      : "mouseOverFrame mb_12"
                  }
                  style={
                    elements?.active_flag ? { opacity: 1 } : { opacity: 0.5 }
                  }
                  onMouseOver={(e) => handleHover(e, elements?.control_id, elements?.frame_id, elements?.isdefault, elements?.control_index)}
                  onMouseOut={handleMouseOut}
                >
                  <Signature
                    width={310}
                    height={100}
                    isDefault={elements?.isdefault}
                    defaultLayout={defaultLayout}
                    activeFlag={elements?.active_flag}
                  />
                  {defaultLayout === false && (
                    <div
                      className="hoverAction"
                      draggable={
                        elements?.isdefault && defaultLayout ? "false" : null
                      }
                    >
                      {HoverData.isHovered &&
                      HoverData.id === elements?.control_id &&
                      HoverData.props?.name === "sign" ? (
                        <EditAndDelete
                          controlId={elements?.control_id}
                          activeFlag={elements?.active_flag}
                          defaultControl={HoverData?.defaultControl}
                          id={HoverData.id}
                          prop={HoverData.props}
                          frame={isframe}
                        />
                      ) : null}
                    </div>
                  )}
                </div>
              ) : elements?.properties?.type === "dropdown" ? (
                <div
                  draggable={
                    elements?.isdefault && defaultLayout ? "false" : null
                  }
                  className={
                    elements?.control_id === dragItem.current
                      ? "mouseOverposition mb_12"
                      : "mouseOverFrame mb_12"
                  }
                  style={
                    elements?.active_flag ? { opacity: 1 } : { opacity: 0.5 }
                  }
                  onMouseOver={(e) => handleHover(e, elements?.control_id, elements?.frame_id, elements?.isdefault, elements?.control_index)}
                  onMouseOut={handleMouseOut}
                >
                  <Dropdown
                    isDefault={elements?.isdefault}
                    defaultLayout={defaultLayout}
                    key={elements?.control_id}
                    id={elements?.control_id}
                    type={elements?.properties?.type}
                    itemvalues={elements?.properties?.items}
                    labelName={elements?.properties?.labelName}
                    activeFlag={elements?.active_flag}
                  />
                  {defaultLayout === false && (
                    <div
                      className="hoverAction"
                      draggable={
                        elements?.isdefault && defaultLayout ? "false" : null
                      }
                    >
                      {HoverData.isHovered &&
                      HoverData.id === elements?.control_id &&
                      HoverData.props?.name === "dropdown" ? (
                        <EditAndDelete
                          controlId={elements?.control_id}
                          activeFlag={elements?.active_flag}
                          defaultControl={HoverData?.defaultControl}
                          id={HoverData.id}
                          prop={HoverData.props}
                          frame={isframe}
                        />
                      ) : null}
                    </div>
                  )}
                </div>
              ) : (
                <div
                  draggable={
                    elements?.isdefault && defaultLayout ? "false" : null
                  }
                  id={
                    elements?.properties?.type === "radio" &&
                    `${elements?.control_id}_formgroup_parent`
                  }
                  className={
                    elements?.control_id === dragItem.current
                      ? "mouseOverposition mb_12"
                      : "mouseOverFrame mb_12"
                  }
                  style={
                    elements?.active_flag ? { opacity: 1 } : { opacity: 0.5 }
                  }
                  onMouseOver={(e) =>
                    Object.keys(activeFormGroup).length === 0 &&
                    handleHover(e, elements?.control_id, elements?.frame_id, elements?.isdefault, elements?.control_index)
                  }
                  onMouseOut={handleMouseOut}
                >
                  {defaultLayout === false || (defaultLayout === true && 
                      elements?.properties?.name == "numberstringssn" &&
                      elements?.frame_name == "Personal") ? (
                    <div
                      className="hoverAction"
                      draggable={
                        elements?.isdefault && defaultLayout ? "false" : null
                      }
                    >
                      {HoverData.isHovered &&
                      HoverData.id === elements?.control_id &&
                      HoverData.props?.name !== "textarea" &&
                      HoverData.props?.name !== "dropdown" &&
                      formGroupChildren?.length === 0 &&
                      Object.keys(activeFormGroup)?.length === 0 ? (
                        <EditAndDelete
                          controlId={elements?.control_id}
                          activeFlag={elements?.active_flag}
                          defaultControl={HoverData.defaultControl}
                          id={HoverData.id}
                          prop={HoverData.props}
                          frameName={elements?.frame_name}
                          frame={isframe}
                        />
                      ) : null}
                    </div>
                  ) : <></>}
                  <>
                    {elements?.properties?.type === "checkbox" && (
                      <label
                        className="biglabelText"
                        draggable={
                          elements?.isdefault && defaultLayout ? "false" : null
                        }
                      >
                        {elements?.properties?.labelName}
                      </label>
                    )}
                    {elements?.properties?.type === "radio" && (
                      <label
                        className="biglabelText"
                        draggable={
                          elements?.isdefault && defaultLayout ? "false" : null
                        }
                      >
                        {elements?.properties?.labelName}
                      </label>
                    )}
                    {elements?.properties?.type === "fileUpload" && (
                      <label
                        className="biglabelText "
                        draggable={
                          elements?.isdefault && defaultLayout ? "false" : null
                        }
                      >
                        {elements?.properties?.labelName}
                      </label>
                    )}
                    <InputField
                      isDefault={elements?.isdefault}
                      defaultLayout={defaultLayout}
                      key={elements?.control_id}
                      minLength={elements?.properties?.minLength}
                      maxLength={elements?.properties?.maxLength}
                      min={elements?.properties?.minValue}
                      max={elements?.properties?.maxValue}
                      pattern={elements?.properties?.pattern}
                      placeholder={elements?.properties?.placeholder}
                      required={elements?.properties?.required}
                      id={elements?.control_id}
                      type={elements?.properties?.type}
                      name={elements?.properties?.name}
                      labelName={elements?.properties?.labelName}
                      itemvalues={elements?.properties?.items}
                      dateFormat={elements?.properties?.dateFormat}
                      dateCondition={elements?.properties?.dateCondition}
                      accept={elements?.properties?.accept}
                      activeFlag={elements?.active_flag}
                      alignStyle={elements?.properties?.alignStyle}
                      isFormGroup={elements?.properties?.isFormGroup}
                      updateSelectedFormGroupOption={
                        updateSelectedFormGroupOption
                      }
                    />
                    {/* FOR FORM GROUP CHILDREN RENDERING START */}
                    {elements.properties.type == "radio" &&
                      elements?.properties?.isFormGroup &&
                      Object.keys(selectedFormGroupOption)?.length > 0 &&
                      selectedFormGroupOption?.control_id ==
                        elements?.control_id && (
                        <div
                          className="dragAndDrop_frame"
                          style={{
                            marginTop: "15px",
                            minHeight: "150px",
                            height: "auto",
                          }}
                          onDragOver={(event) => dragOver(event, "")}
                          onDrop={(e) =>
                            dragStartFrom === "control" &&
                            Object.keys(activeFormGroup).length > 0 &&
                            handlePayloadArr(e)
                          }
                        >
                          <div className="row">
                            {formGroupChildren?.map((elements, index) => (
                              <div
                                className={
                                  elements?.properties?.name === "paragraph"
                                    ? "col-md-12"
                                    : "col-md-6"
                                }
                                key={index}
                                draggable={
                                  elements?.isdefault && defaultLayout
                                    ? "false"
                                    : "true"
                                }
                                style={{
                                  cursor:
                                    elements?.isdefault && defaultLayout
                                      ? "auto"
                                      : "move",
                                }}
                                onDragStart={(event) =>
                                  !defaultLayout
                                    ? dragStart(event, elements?.control_id)
                                    : null
                                }
                                onDragEnter={(event) =>
                                  !defaultLayout
                                    ? dragEnter(event, elements?.control_id)
                                    : null
                                }
                                onDragEnd={(e) =>
                                  dragStartFrom === "frame" && handleSort(e)
                                }
                                onDragOver={(event) =>
                                  !defaultLayout
                                    ? dragOver(event, elements?.control_id)
                                    : null
                                }
                                onDragLeave={(event) =>
                                  !defaultLayout
                                    ? dragLeave(event, elements?.control_id)
                                    : null
                                }
                                onDrop={(e) => (e.target.style.border = "")}
                              >
                                {elements?.properties?.type === "textarea" ? (
                                  <div
                                    draggable={
                                      elements?.isdefault && defaultLayout
                                        ? "false"
                                        : null
                                    }
                                    className={
                                      elements?.control_id === dragItem.current
                                        ? "drag mouseOverposition"
                                        : "mouseOverFrame mb_12"
                                    }
                                    style={
                                      elements.active_flag
                                        ? { opacity: 1 }
                                        : { opacity: 0.5 }
                                    }
                                    onMouseOver={(e) =>
                                      handleHover(e, elements?.control_id, elements?.frame_id, elements?.isdefault, elements?.control_index)
                                    }
                                    onMouseOut={handleMouseOut}
                                  >
                                    <TextArea
                                      isDefault={elements?.isdefault}
                                      defaultLayout={defaultLayout}
                                      key={elements?.control_id}
                                      id={elements?.control_id}
                                      type={elements.properties?.type}
                                      labelName={
                                        elements?.properties?.labelName
                                      }
                                      minLength={
                                        elements?.properties?.minLength
                                      }
                                      placeholder={
                                        elements?.properties?.placeholder
                                      }
                                      activeFlag={elements?.active_flag}
                                      cols={elements?.properties?.cols}
                                      rows={elements?.properties?.rows}
                                      maxLength={elements.properties.maxLength}
                                    />
                                    {defaultLayout === false && (
                                      <div
                                        className="hoverAction"
                                        draggable={
                                          elements.isdefault && defaultLayout
                                            ? "false"
                                            : null
                                        }
                                      >
                                        {HoverData.isHovered &&
                                        HoverData.id === elements?.control_id &&
                                        HoverData.props?.name === "textarea" ? (
                                          <EditAndDelete
                                            controlId={elements?.control_id}
                                            activeFlag={elements?.active_flag}
                                            defaultControl={HoverData?.defaultControl}
                                            id={HoverData.id}
                                            prop={HoverData.props}
                                            frame={isframe}
                                          />
                                        ) : null}
                                      </div>
                                    )}
                                  </div>
                                ) : elements?.properties?.name ===
                                  "banklookup" ? (
                                  <div
                                    draggable={
                                      elements?.isdefault && defaultLayout
                                        ? "false"
                                        : null
                                    }
                                    className={
                                      elements?.control_id === dragItem.current
                                        ? "drag mouseOverposition"
                                        : "mouseOverFrameBanklookup mb_12"
                                    }
                                    style={
                                      elements?.active_flag
                                        ? { opacity: 1 }
                                        : { opacity: 0.5 }
                                    }
                                    onMouseOver={(e) =>
                                      handleHover(e, elements?.control_id, elements?.frame_id, elements?.isdefault, elements?.control_index)
                                    }
                                    onMouseOut={handleMouseOut}
                                  >
                                    <div>
                                      <img src={BankImage} alt="" />
                                      <button
                                        style={{ width: "130px" }}
                                        className="MainButton"
                                      >
                                        Connect Bank
                                      </button>
                                    </div>
                                  </div>
                                ) : elements?.properties?.name ===
                                  "paragraph" ? (
                                  <div
                                    draggable={
                                      elements?.isdefault && defaultLayout
                                        ? "false"
                                        : null
                                    }
                                    className={
                                      elements?.control_id === dragItem.current
                                        ? "drag mouseOverposition"
                                        : "mouseOverFrame mb_12"
                                    }
                                    style={
                                      elements?.active_flag
                                        ? { opacity: 1 }
                                        : { opacity: 0.5 }
                                    }
                                    onMouseOver={(e) =>
                                      handleHover(e, elements?.control_id, elements?.frame_id, elements?.isdefault, elements?.control_index)
                                    }
                                    onMouseOut={handleMouseOut}
                                  >
                                    <p
                                      style={{
                                        fontSize: `${elements?.properties?.fontSize}px`,
                                        fontWeight: elements?.properties?.bold
                                          ? "bold"
                                          : "normal",
                                        fontStyle: elements?.properties?.italic
                                          ? "italic"
                                          : "normal",
                                      }}
                                      draggable={
                                        elements?.isdefault && defaultLayout
                                          ? "false"
                                          : null
                                      }
                                    >
                                      {elements?.properties?.paragraph}
                                    </p>
                                    {defaultLayout === false && (
                                      <div
                                        className="hoverAction"
                                        draggable={
                                          elements?.isdefault && defaultLayout
                                            ? "false"
                                            : null
                                        }
                                      >
                                        {HoverData.isHovered &&
                                        HoverData.id === elements?.control_id &&
                                        HoverData.props?.name ===
                                          "paragraph" ? (
                                          <EditAndDelete
                                            controlId={elements?.control_id}
                                            activeFlag={elements?.active_flag}
                                            defaultControl={HoverData?.defaultControl}
                                            id={HoverData.id}
                                            prop={HoverData.props}
                                            frame={isframe}
                                          />
                                        ) : null}
                                      </div>
                                    )}
                                  </div>
                                ) : elements?.properties?.name === "sign" ? (
                                  <div
                                    draggable={
                                      elements?.isdefault && defaultLayout
                                        ? "false"
                                        : null
                                    }
                                    className={
                                      elements?.control_id === dragItem.current
                                        ? "drag mouseOverposition"
                                        : "mouseOverFrame mb_12"
                                    }
                                    style={
                                      elements?.active_flag
                                        ? { opacity: 1 }
                                        : { opacity: 0.5 }
                                    }
                                    onMouseOver={(e) =>
                                      handleHover(e, elements?.control_id, elements?.frame_id, elements?.isdefault, elements?.control_index)
                                    }
                                    onMouseOut={handleMouseOut}
                                  >
                                    <Signature
                                      width={310}
                                      height={100}
                                      isDefault={elements?.isdefault}
                                      defaultLayout={defaultLayout}
                                      activeFlag={elements?.active_flag}
                                    />
                                    {defaultLayout === false && (
                                      <div
                                        className="hoverAction"
                                        draggable={
                                          elements?.isdefault && defaultLayout
                                            ? "false"
                                            : null
                                        }
                                      >
                                        {HoverData.isHovered &&
                                        HoverData.id === elements?.control_id &&
                                        HoverData.props?.name === "sign" ? (
                                          <EditAndDelete
                                            controlId={elements?.control_id}
                                            activeFlag={elements?.active_flag}
                                            defaultControl={HoverData?.defaultControl}
                                            id={HoverData.id}
                                            prop={HoverData.props}
                                            frame={isframe}
                                          />
                                        ) : null}
                                      </div>
                                    )}
                                  </div>
                                ) : elements?.properties?.type ===
                                  "dropdown" ? (
                                  <div
                                    draggable={
                                      elements?.isdefault && defaultLayout
                                        ? "false"
                                        : null
                                    }
                                    className={
                                      elements?.control_id === dragItem.current
                                        ? "mouseOverposition mb_12"
                                        : "mouseOverFrame mb_12"
                                    }
                                    style={
                                      elements?.active_flag
                                        ? { opacity: 1 }
                                        : { opacity: 0.5 }
                                    }
                                    onMouseOver={(e) =>
                                      handleHover(e, elements?.control_id, elements?.frame_id, elements?.isdefault, elements?.control_index)
                                    }
                                    onMouseOut={handleMouseOut}
                                  >
                                    <Dropdown
                                      isDefault={elements?.isdefault}
                                      defaultLayout={defaultLayout}
                                      key={elements?.control_id}
                                      id={elements?.control_id}
                                      type={elements?.properties?.type}
                                      itemvalues={elements?.properties?.items}
                                      labelName={
                                        elements?.properties?.labelName
                                      }
                                      activeFlag={elements?.active_flag}
                                    />
                                    {defaultLayout === false && (
                                      <div
                                        className="hoverAction"
                                        draggable={
                                          elements?.isdefault && defaultLayout
                                            ? "false"
                                            : null
                                        }
                                      >
                                        {HoverData.isHovered &&
                                        HoverData.id === elements?.control_id &&
                                        HoverData.props?.name === "dropdown" ? (
                                          <EditAndDelete
                                            controlId={elements?.control_id}
                                            activeFlag={elements?.active_flag}
                                            defaultControl={HoverData?.defaultControl}
                                            id={HoverData.id}
                                            prop={HoverData.props}
                                            frame={isframe}
                                          />
                                        ) : null}
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div
                                    draggable={
                                      elements?.isdefault && defaultLayout
                                        ? "false"
                                        : null
                                    }
                                    className={
                                      elements?.control_id === dragItem.current
                                        ? "mouseOverposition mb_12"
                                        : "mouseOverFrame mb_12"
                                    }
                                    style={
                                      elements?.active_flag
                                        ? { opacity: 1 }
                                        : { opacity: 0.5 }
                                    }
                                    onMouseOver={(e) =>
                                      handleHover(e, elements?.control_id, elements?.frame_id, elements?.isdefault, elements?.control_index)
                                    }
                                    onMouseOut={handleMouseOut}
                                  >
                                    {defaultLayout === false && (
                                      <div
                                        className="hoverAction"
                                        draggable={
                                          elements?.isdefault && defaultLayout
                                            ? "false"
                                            : null
                                        }
                                      >
                                        {HoverData.isHovered &&
                                        HoverData.id === elements?.control_id &&
                                        HoverData.props?.name !== "textarea" &&
                                        HoverData.props?.name !== "dropdown" ? (
                                          <EditAndDelete
                                            controlId={elements?.control_id}
                                            activeFlag={elements?.active_flag}
                                            defaultControl={HoverData?.defaultControl}
                                            id={HoverData.id}
                                            prop={HoverData.props}
                                            frame={isframe}
                                          />
                                        ) : null}
                                      </div>
                                    )}
                                    <>
                                      {elements?.properties?.type ===
                                        "checkbox" && (
                                        <label
                                          className="biglabelText"
                                          draggable={
                                            elements?.isdefault && defaultLayout
                                              ? "false"
                                              : null
                                          }
                                        >
                                          {elements?.properties?.labelName}
                                        </label>
                                      )}
                                      {elements?.properties?.type ===
                                        "radio" && (
                                        <label
                                          className="biglabelText"
                                          draggable={
                                            elements?.isdefault && defaultLayout
                                              ? "false"
                                              : null
                                          }
                                        >
                                          {elements?.properties?.labelName}
                                        </label>
                                      )}
                                      {elements?.properties?.type ===
                                        "fileUpload" && (
                                        <label
                                          className="biglabelText "
                                          draggable={
                                            elements?.isdefault && defaultLayout
                                              ? "false"
                                              : null
                                          }
                                        >
                                          {elements?.properties?.labelName}
                                        </label>
                                      )}
                                      <InputField
                                        isDefault={elements?.isdefault}
                                        defaultLayout={defaultLayout}
                                        key={elements?.control_id}
                                        minLength={
                                          elements?.properties?.minLength
                                        }
                                        maxLength={
                                          elements?.properties?.maxLength
                                        }
                                        min={elements?.properties?.minValue}
                                        max={elements?.properties?.maxValue}
                                        pattern={elements?.properties?.pattern}
                                        placeholder={
                                          elements?.properties?.placeholder
                                        }
                                        required={
                                          elements?.properties?.required
                                        }
                                        id={elements?.control_id}
                                        type={elements?.properties?.type}
                                        name={elements?.properties?.name}
                                        labelName={
                                          elements?.properties?.labelName
                                        }
                                        itemvalues={elements?.properties?.items}
                                        dateFormat={
                                          elements?.properties?.dateFormat
                                        }
                                        dateCondition={
                                          elements?.properties?.dateCondition
                                        }
                                        accept={elements?.properties?.accept}
                                        activeFlag={elements?.active_flag}
                                        alignStyle={
                                          elements?.properties?.alignStyle
                                        }
                                        isFormGroup={
                                          elements?.properties?.isFormGroup
                                        }
                                      />
                                    </>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    {/* FOR FORM GROUP CHILDREN RENDERING END */}
                  </>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Frame;
