import React, { useEffect } from 'react'
import { useStateValue } from "../../StateProvider/StateProvider";
import DragIcon from "../../assets/images/drag_icon.svg";

const Controlselect = () => {
  const [{ Controls, selectedFrame }] = useStateValue();
  useEffect(() => {
  }, [selectedFrame])
  return (
    <>
      {Controls.length === 0 && Object.keys(selectedFrame).length >= 0 ? (
        <div className="nodataFrame">
          <img src={DragIcon} className="mb_16" />
          <h1 className="nodataText textAlignCenter">
            Drag and Drop the Controls to Design a Dynamic Form!
          </h1>
        </div>
      ) : null}
    </>
  );
}

export default Controlselect