// import { useStateValue } from "./StateProvider/StateProvider";
import { Post } from "../_common/httpService";

export const updateRadioControllerItemName = (data, formGroupChildren, dispatch) => {
  // CHANGING THE RADIO CONTROLLER ITEMS NAME INTO AS PER THEIR'S CONTROL INDEX
  let tempControllers = [...data];

  tempControllers?.map((ele, i) => {

    if (ele?.properties?.type == "radio") {

      ele?.properties?.items?.map((elmt, ind) => {

        const radioNameCIndex = elmt?.name?.charAt(elmt?.name.length - 1);

        if (ele?.control_index !== Number(radioNameCIndex)) {

          elmt.name = `f${ele?.frame_id}_radio_${ele.control_index}`;
          elmt.idFor = `f${ele?.frame_id}_radio_i${ind+1}_${ele.control_index}`;
          
          // ele.properties.name = `f${ele?.frame_id}_p_radio_${ele.control_index}`;
          if (ele?.properties?.items?.length - 1 == ind) {
            tempControllers[i].isformgroup_child = formGroupChildren.length > 0 ? true : false;
            tempControllers[i].ref_no = ele.control_id;
            const updateRadioController = async () => {
              let repns = await Post(`controls/create`, {
                controls: [tempControllers[i]],
              });
              if (repns.statusCode == 200) {
                dispatch({
                  type: "SET_CONTROL",
                  item: repns.data,
                });
              }
            };
            updateRadioController();
          }
        }
      });
      // ----- FOR FORM GROUP CHILDREN START -----
      if (ele?.properties?.isFormGroup) {
        if (ele?.children?.length > 0) {
          ele?.children?.map((childEle, inx) => {

            if (childEle?.properties?.type == "radio") {
              childEle?.properties?.items?.map((childElmt, ix) => {

                const childRadioNameCIndex = childElmt?.name?.charAt(childElmt?.name.length - 1);

                if (childEle?.control_index !== Number(childRadioNameCIndex)) {

                  childElmt.name = `f${ele?.frame_id}_radio_g${childEle.group_by}_${childEle.control_index}`;
                  childElmt.idFor = `f${ele?.frame_id}_radio_g${childEle.group_by}_i${ix+1}_${childEle.control_index}`;
                  childEle.name = `f${ele?.frame_id}_p_radio_g${childEle.group_by}_${childEle.control_index}`;

                  if (childEle?.properties?.items?.length - 1 == ix) {
                    ele.children[inx].isformgroup_child = true;
                    ele.children[inx].ref_no = childEle.control_id;
                    ele.children[inx].frame_id = ele.frame_id;

                    const updateChildRadioController = async () => {
                      let repns = await Post(`controls/create`, {
                        controls: [ele.children[inx]],
                      });
                      if (repns.statusCode == 200) {
                        dispatch({
                          type: "SET_CONTROL",
                          item: repns.data,
                        });
                      }
                    };
                    updateChildRadioController();
                  }
                }
              });
            }

          });
        }
      }
      // ----- FOR FORM GROUP CHILDREN END -----
    }
  });
};
