import Select from 'react-select';
import React from 'react';

const customStyles = {
  dropdownIndicator: (provided, state) => ({
    display: 'none'
  }),
  indicatorSeparator: (provided, state) => ({
    display: 'none'
  })
};

const SearchInput = ({
  key,
  id,
  options,
  name,
  label,
  value,
  defaultValue,
  isDisabled,
  isLoading,
  isMulti,
  isClearable,
  isRtl,
  isSearchable,
  getOptionLabel,
  getOptionValue,
  handleChange,
  placeholder,
  initialValue
}) => {
  return (
    <div>
      <Select
      initialValue={initialValue}
        styles={customStyles}
        placeholder={placeholder}
        label={label}
        key={key}
        id={id}
        className='basic-single'
        classNamePrefix='select'
        drop
        defaultValue={defaultValue && { [label]: defaultValue }}
        isDisabled={isDisabled}
        isMulti={isMulti}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        name={name}
        value={value}
        options={options}
        onChange={handleChange}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        menuShouldScrollIntoView={false}
        menuPlacement={'auto'}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#fff2f2',
            primary: '#aa4f4f'
          }
        })}
      />
    </div>
  );
};

// export default React.memo(SearchInput);
export default SearchInput;
