import React, { useEffect } from "react";
import { useStateValue } from "../../StateProvider/StateProvider";
import NewFrameIcon from "../../assets/images/newFrame_icon.svg";

const Frameselect = () => {
  const [{ frameLength, selectedFrame }] = useStateValue();
  useEffect(() => {}, [frameLength, selectedFrame]);
  return (
    <>
      {frameLength === 0 && (
        <div className="nodataFrame">
          <img src={NewFrameIcon} className="mb_16" />
          <h1 className="nodataText">
            Create a Frame to Build a Form Designer!
          </h1>
        </div>
      )}
      {frameLength !== 0 && Object.keys(selectedFrame).length <= 0 && (
        <div className="nodataFrame">
          <img src={NewFrameIcon} className="mb_16" />
          <h1 className="nodataText">
            Create New Frames or Select a Frame to Review the Existing Frames!
          </h1>
        </div>
      )}
    </>
  );
};

export default Frameselect;
