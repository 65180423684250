import React, { useState, useEffect } from "react";
import { Get } from "../_common/httpService";
import { useNavigate } from "react-router-dom";
import MainLogo from "./../assets/images/theecode-logo.svg";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Signature from "../CommonComponent/Signature/Signature";
import BankImage from "../assets/images/bank.png";
import infoIcon from "../assets/images/infoIcon.svg";
import { useStateValue } from "../StateProvider/StateProvider";

const Preview = () => {
  const navigate = useNavigate();
  const [{activeFormGroupPreview, formGroupChildrenPreview}, dispatch] = useStateValue()
  const Layout_id = sessionStorage.getItem("layout_Id");
  const personaId = sessionStorage.getItem("personaId");
  const defaultLayout = JSON.parse(sessionStorage.getItem("isDefault"));
  const [data, setData] = useState([]);
  const [activeChanges, setActiveChanges] = useState(true);
  const [fieldProperties, setFieldProperties] = useState([]);
  const [changeableFieldProperties, setChangeableFieldProperties] = useState(
    []
  );
  const [showInfoIcon, setShowInfoIcon] = useState(false);
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);
  const [selectedFormGroupOptionPreview, setSelectedFormGroupOptionPreview] = useState({});

  const fieldMethods = {
    mask: {
      PhoneNumberFormatter: (number) => {
        if (!number) return number;
        const PhoneNumber = number.replace(/[^\d]/g, "");
        if (PhoneNumber.length === 0) {
          return "";
        } else if (PhoneNumber.length > 0 && PhoneNumber.length < 3) {
          return `(${PhoneNumber}`;
        } else if (PhoneNumber.length >= 4 && PhoneNumber.length < 6) {
          return `(${PhoneNumber.slice(0, 3)}) ${PhoneNumber.slice(3)}`;
        } else if (PhoneNumber.length >= 7) {
          return `(${PhoneNumber.slice(0, 3)}) ${PhoneNumber.slice(
            3,
            6
          )}-${PhoneNumber.slice(6, 10)}`;
        } else {
          return number;
        }
      },
      SSN_NumberFormatter: (number) => {
        let cardValue = number
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,2})(\d{0,4})/);
        cardValue = !cardValue[2]
          ? cardValue[1]
          : `${cardValue[1]}-${cardValue[2]}${`${
              cardValue[3] ? `-${cardValue[3]}` : ""
            }`}${`${cardValue[4] ? `-${cardValue[4]}` : ""}`}`;
        return cardValue;
      },
    },
    regex: {
      OnlyNumbers: (data) => {
        const value = data.replace(/[^\d]/g, "");
        if (!value) {
          return "";
        } else {
          return value;
        }
      },
    },
    errorMessage: function (errorName, FI, CI) {
      let errorValue;
      switch (errorName) {
        case "valueMissing":
          errorValue = `Kindly Provide ${this[FI][CI].labelName}`;
          break;
        case "tooShort":
          errorValue = `Kindly Enter ${this[FI][CI].labelName} Greater Than ${
            this[FI][CI].minLength - 1
          } Character`;
          break;
        case "tooLong":
          errorValue = `Kindly Enter ${this[FI][CI].labelName} Less Than ${
            this[FI][CI].maxLength + 1
          } Character`;
          break;
        case "patternMismatch":
          errorValue = `Kinldy Provide Valid ${this[FI][CI].labelName}`;
          break;
        case "valueLow":
          errorValue = `Kinldy Enter ${this[FI][CI].labelName} Greater Than ${
            this[FI][CI].minValue - 1
          } `;
          break;
        case "valueHigh":
          errorValue = `Kinldy Enter ${this[FI][CI].labelName} Less Than ${
            this[FI][CI].maxValue + 1
          }`;
          break;
        case "patternMismatch_password":
          errorValue = `Kinldy Provide Valid ${this[FI][CI].labelName} Containting Atleast One UpperCase Letter, One LowerCase Letter, One Special Character and One Alphanumeric Character`;
          break;
        case "greaterThan18":
          errorValue = `kindly Enter ${this[FI][CI].labelName} Greater Than 18 Years`;
          break;
        case "greaterThan50":
          errorValue = `kindly Enter ${this[FI][CI].labelName} Greater Than 50 Years`;
          break;
        case "greaterThanCurrentDate":
          errorValue = `kindly Enter ${this[FI][CI].labelName} Greater Than Current Date`;
          break;
        case "lesserThanCurrentDate":
          errorValue = `kindly Enter ${this[FI][CI].labelName} Lesser Than Current Date`;
          break;
        case "invalidDate":
          errorValue = `kindly Enter Valid ${this[FI][CI].labelName}`;
          break;
        case "fileFormatMisMatch":
          let fileFormatStr = "";
          let counter = 0;
          for (const fileFormat in this[FI][CI].file.fileFormat) {
            if (!counter) {
              fileFormatStr = fileFormat;
            } else if (
              counter ===
              Object.keys(this[FI][CI].file.fileFormat).length - 1
            ) {
              fileFormatStr = fileFormatStr + " or " + fileFormat;
            } else if (counter) {
              fileFormatStr = fileFormatStr + ", " + fileFormat;
            }
            counter = counter + 1;
          }

          errorValue = `kindly Upload ${this[FI][CI].labelName} of ${
            counter > 1 ? "types" : "type"
          } ${fileFormatStr}`;
          break;
        case "fileSizeIsLarge":
          errorValue = `kindly Upload ${this[FI][CI].labelName} Less Than ${
            this[FI][CI].file.size / 1024 / 1024
          } MB`;
          break;
        default:
          errorValue = "";
      }
      return errorValue;
    },
    validate: function (value, FI, CI, inputName, rawValue) {
      const updateValidation = (propertyValue) => {
        for (const propertyKey in this[FI].controls[CI].validity) {
          if (propertyKey !== propertyValue) {
            this[FI].controls[CI].validity[propertyKey] = false;
          } else {
            this[FI].controls[CI].validity[propertyKey] = true;
          }
        }
      };

      switch (inputName) {
        case "date":
          if (
            value?.$d &&
            value?.$d instanceof Date &&
            !isNaN(value?.$d.valueOf())
          ) {
            const age = Math.abs(
              new Date(Date.now() - value?.$d.getTime()).getUTCFullYear() - 1970
            );
            const isDateLesser = new Date(value.$d) < new Date();
            const isDateGreater = new Date(value.$d) > new Date();
            if (
              fieldProperties[FI][CI].dateCondition === "min_18" &&
              age < 18
            ) {
              updateValidation("greaterThan18");
            } else if (
              fieldProperties[FI][CI].dateCondition === "min_50" &&
              age < 50
            ) {
              updateValidation("greaterThan50");
            } else if (
              fieldProperties[FI][CI].dateCondition === "l_cdate" &&
              isDateGreater
            ) {
              updateValidation("lesserThanCurrentDate");
            } else if (
              fieldProperties[FI][CI].dateCondition === "g_cdate" &&
              isDateLesser
            ) {
              updateValidation("greaterThanCurrentDate");
            } else {
              updateValidation("valid");
            }
          } else {
            if (fieldProperties[FI][CI].required && !value?.$D) {
              updateValidation("valueMissing");
            } else {
              updateValidation("invalidDate");
            }
          }
          break;
        case "checkbox":
        case "radio":
          if (!fieldProperties[FI][CI].required) {
            break;
          }

          for (let i = 0; i < value.length; i++) {
            if (this[FI].controls[CI].selectOptionFields[value[i]].checked) {
              this[FI].controls[CI].validity.valueMissing = false;
              this[FI].controls[CI].validity.valid = true;
              break;
            } else {
              this[FI].controls[CI].validity.valueMissing = true;
              this[FI].controls[CI].validity.valid = false;
            }
          }
          break;
        case "dropdown":
          if (
            (value.length === 0 || value === "default") &&
            fieldProperties[FI][CI].required
          ) {
            this[FI].controls[CI].validity.valueMissing = true;
            this[FI].controls[CI].validity.valid = false;
          } else {
            this[FI].controls[CI].validity.valueMissing = false;
            this[FI].controls[CI].validity.valid = true;
          }
          break;
        case "fileUpload":
          let fileFormatMisMatch = false;
          let isValid = false;
          let islarger = false;
          for (let i = value.length - 1; i >= 0; i--) {
            if (value[i].size > fieldProperties[FI][CI].file.size) {
              islarger = true;
              break;
            }

            for (const fileFormat in fieldProperties[FI][CI].file.fileFormat) {
              if (
                value[i].type ===
                fieldProperties[FI][CI].file.fileFormat[fileFormat]
              ) {
                isValid = true;
                break;
              }
            }

            if (!isValid) {
              break;
            }
          }

          if (!isValid || islarger || value.length === 0) {
            rawValue.target.value = "";
            value = [];
            this[FI].controls[CI].originalValue = [];
            this[FI].controls[CI].inputFieldValue = [];
            fileFormatMisMatch = islarger ? false : true;
          }

          if (fileFormatMisMatch) {
            updateValidation("fileFormatMisMatch");
          } else if (islarger) {
            updateValidation("fileSizeIsLarge");
          } else if (value.length === 0 && fieldProperties[FI][CI].required) {
            updateValidation("valueMissing");
          } else {
            updateValidation("valid");
          }
          break;
        default:
          if (value.length === 0) {
            if (!fieldProperties[FI][CI].required) {
              updateValidation("valid");
              break;
            }
            updateValidation("valueMissing");
            break;
          } else if (
            fieldProperties[FI][CI].minLength &&
            value.length < fieldProperties[FI][CI].minLength &&
            value.length > 0
          ) {
            updateValidation("tooShort");
            break;
          } else if (
            fieldProperties[FI][CI].minValue &&
            value < fieldProperties[FI][CI].minValue
          ) {
            updateValidation("valueLow");
            break;
          } else if (
            fieldProperties[FI][CI].pattern &&
            !new RegExp(fieldProperties[FI][CI].pattern).test(value)
          ) {
            if (inputName === "password") {
              updateValidation("patternMismatch_password");
            } else {
              updateValidation("patternMismatch");
            }
            break;
          } else if (
            fieldProperties[FI][CI].maxLength &&
            value.length > fieldProperties[FI][CI].maxLength
          ) {
            updateValidation("tooLong");
            break;
          } else if (
            fieldProperties[FI][CI].maxValue &&
            value > fieldProperties[FI][CI].maxValue
          ) {
            updateValidation("valueHigh");
            break;
          } else {
            updateValidation("valid");
          }
          break;
      }
    },
  };

  useEffect(() => {
    getLayoutData();
  }, []);

  const setMaxDate = (dateCondition) => {
    const today = new Date();

    if (dateCondition === "min_18") {
      return new Date(
        today.getFullYear() - 18,
        today.getMonth(),
        today.getDate()
      );
    } else if (dateCondition === "min_50") {
      return new Date(
        today.getFullYear() - 50,
        today.getMonth(),
        today.getDate()
      );
    } else if (dateCondition === "l_cdate") {
      return new Date();
    } else {
      return null;
    }
  };

  const setMinDate = (dateCondition) => {
    if (dateCondition == "g_cdate") {
      return new Date();
    } else {
      return null;
    }
  };

  const getLayoutData = async () => {
    let res = await Get(`controls/getByLayout/${Layout_id}`);
    if (res.statusCode === 200) {
      setData(res.data);
      setActiveChanges(res.data[0].active_changes_flag);
      const newFieldProperties = [];
      const newChangeableFieldProperties = [];

      res.data.map((layout) => {
        const controls = [];
        const editControls = [];
        if (layout.active_flag) {
          layout.controls.map((control) => {
            const fieldProperty = {
              labelName: control.properties.labelName,
              name: control.properties.name,
              controlName: control.control_name,
              maxLength: control.properties.maxLength,
              minLength: control.properties.minLength,
              minValue: control.properties.minValue,
              maxValue: control.properties.maxValue,
              pattern: control.properties.pattern,
              required: control.properties.required,
            };

            const editFieldProperty = {
              error: null,
              touched: null,
              originalValue:
                control.properties.name === "checkbox" ||
                control.properties.name === "radio" ||
                control.properties.name === "fileUpload"
                  ? []
                  : "",
              inputFieldValue:
                control.properties.name === "checkbox" ||
                control.properties.name === "radio" ||
                control.properties.name === "fileUpload"
                  ? []
                  : "",
              validity: {
                valueMissing: false,
                valid: false,
              },
            };

            switch (control.properties.name) {
              case "date":
                editFieldProperty.validity.greaterThan18 = false;
                editFieldProperty.validity.greaterThan50 = false;
                editFieldProperty.validity.greaterThanCurrentDate = false;
                editFieldProperty.validity.lesserThanCurrentDate = false;
                editFieldProperty.validity.invalidDate = false;

                fieldProperty.dateCondition = control.properties.dateCondition;
                fieldProperty.dateFormat = control.properties.dateFormat;
                break;
              case "fileUpload":
                fieldProperty.file = {
                  fileFormat: control.properties.fileFormat,
                };
                fieldProperty.file.size =
                  control.properties.maxLength * 1024 * 1024;
                editFieldProperty.initialTouch = false;
                editFieldProperty.validity.fileFormatMisMatch = false;
                editFieldProperty.validity.fileSizeIsLarge = false;

                const fileFormatModal = {
                  jpeg: "image/jpeg",
                  jpg: "image/jpeg",
                  pdf: "application/pdf",
                  png: "image/png",
                  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  csv: "text/csv",
                  zip: "application/x-zip-compressed",
                };
                const fileFormatAccepted = {};

                for (const fileFormat in control.properties.fileFormat) {
                  if (control.properties.fileFormat[fileFormat]) {
                    fileFormatAccepted[fileFormat] =
                      fileFormatModal[fileFormat];
                  }
                }

                fieldProperty.file.fileFormat = fileFormatAccepted;
                break;
              case "number":
                editFieldProperty.validity.valueHigh = false;
                editFieldProperty.validity.valueLow = false;
                editFieldProperty.validity.tooLong = false;
                editFieldProperty.validity.tooShort = false;
                editFieldProperty.validity.patternMismatch = false;
                break;
              case "checkbox":
              case "radio":
                control.properties.items.map((item) => {
                  editFieldProperty.selectOptionFields = {
                    [item.value]: {
                      checked: false,
                    },
                  };
                });
                editFieldProperty.selectOptionFields = {};
                break;
              case "password":
                editFieldProperty.validity.tooLong = false;
                editFieldProperty.validity.tooShort = false;
                editFieldProperty.validity.patternMismatch_password = false;
                break;
              default:
                editFieldProperty.validity.tooLong = false;
                editFieldProperty.validity.tooShort = false;
                editFieldProperty.validity.patternMismatch = false;
            }

            controls.push(fieldProperty);
            editControls.push(editFieldProperty);
          });
        }
        newFieldProperties.push(controls);
        newChangeableFieldProperties.push({
          controls: editControls,
          isSubmitted: false,
        });
      });
      setFieldProperties(newFieldProperties);
      setChangeableFieldProperties(newChangeableFieldProperties);
    }
  };

  // HTML convertion
  // const html_convertion = async () => {
  //   try {
  //     let resp = await axios(
  //       `${http}://${bName}.${process.env.REACT_APP_BUSINESSNAME_ENDURL}/admin-api/api/fetch-loancount/${Layout_id}`
  //     );
  //     if (resp.data.statusCode === 200) {
  //       if (+resp.data.data[0].count === 0) {
  //         let res = await Get(`layout/publish/${Layout_id}`);
  //         if (res.statusCode === 200) {
  //           sessionStorage.setItem("isPublishing", false);
  //           navigate("/publish");
  //         } else if (res.statusCode === 400) {
  //           sessionStorage.setItem("isPublishing", true);
  //           navigate("/publish");
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const html_convertion = async () => {
    try {
      let resp = await Get(`layout/loancount/${Layout_id}`);
      if (resp.statusCode === 200) {
        if (+resp.loanCount === 0) {
          setShowInfoIcon(false);
          let res = await Get(`layout/publish/${Layout_id}`);
          if (res.statusCode === 200) {
            sessionStorage.setItem("isPublishing", false);
            navigate("/publish");
          } else if (res.statusCode === 400) {
            sessionStorage.setItem("isPublishing", true);
            navigate("/publish");
          }
        } else {
          setShowInfoIcon(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e, FI, CI) => {
    const newChangeableFieldProperties = { ...changeableFieldProperties };
    let value;
    let maskValue;

    // To store the value in the state
    switch (fieldProperties[FI][CI].name) {
      case "numberstringphone":
        maskValue = fieldMethods.mask.PhoneNumberFormatter(e.target.value);
        value = fieldMethods.regex.OnlyNumbers(maskValue);
        newChangeableFieldProperties[FI].controls[CI].originalValue = value;
        newChangeableFieldProperties[FI].controls[CI].inputFieldValue =
          maskValue;
        break;
      case "numberstringssn":
        maskValue = fieldMethods.mask.SSN_NumberFormatter(e.target.value);
        value = fieldMethods.regex.OnlyNumbers(maskValue);
        newChangeableFieldProperties[FI].controls[CI].originalValue = value;
        newChangeableFieldProperties[FI].controls[CI].inputFieldValue =
          maskValue;
        break;
      case "date":
        newChangeableFieldProperties[FI].controls[CI].originalValue = e;
        newChangeableFieldProperties[FI].controls[CI].inputFieldValue = e;
        break;
      case "radio":
      case "checkbox":
        newChangeableFieldProperties[FI].controls[CI].selectOptionFields[
          e.target.value
        ] = {
          checked: e.target.checked,
        };
        if (
          newChangeableFieldProperties[FI].controls[CI].originalValue.includes(
            e.target.value
          )
        ) {
          break;
        }
        newChangeableFieldProperties[FI].controls[CI].originalValue.push(
          e.target.value
        );
        newChangeableFieldProperties[FI].controls[CI]?.inputFieldValue.push(
          e.target.value
        );
        break;
      case "fileUpload":
        const selectedFiles = [];
        for (const files in e.target.files) {
          if (e.target.files[files].type) {
            selectedFiles.push(e.target.files[files]);
          }
        }
        newChangeableFieldProperties[FI].controls[CI].originalValue =
          selectedFiles;
        newChangeableFieldProperties[FI].controls[CI].inputFieldValue =
          selectedFiles;
        break;
      case "number":
        value = fieldMethods.regex.OnlyNumbers(e.target.value);
        newChangeableFieldProperties[FI].controls[CI].originalValue = value;
        newChangeableFieldProperties[FI].controls[CI].inputFieldValue = value;
        break;
      default:
        newChangeableFieldProperties[FI].controls[CI].originalValue =
          e.target.value;
        newChangeableFieldProperties[FI].controls[CI].inputFieldValue =
          e.target.value;
    }

    // To Validate The Input Field
    fieldMethods.validate.call(
      newChangeableFieldProperties,
      newChangeableFieldProperties[FI].controls[CI].originalValue,
      FI,
      CI,
      fieldProperties[FI][CI].name,
      fieldProperties[FI][CI].name === "fileUpload" ? e : undefined
    );

    // Break If Value is Valid
    if (newChangeableFieldProperties[FI].controls[CI].validity.valid) {
      newChangeableFieldProperties[FI].controls[CI].error = null;
      setChangeableFieldProperties(newChangeableFieldProperties);
      return;
    }

    // To Find The Error
    for (const errorName in newChangeableFieldProperties[FI].controls[CI]
      .validity) {
      if (newChangeableFieldProperties[FI].controls[CI].validity[errorName]) {
        newChangeableFieldProperties[FI].controls[CI].error =
          fieldMethods.errorMessage.call(fieldProperties, errorName, FI, CI);
        break;
      }
    }

    // To make the field touched if the value is valid to prevent handleblur to run unnecessarily.
    if (!changeableFieldProperties[FI].controls[CI].validity.valueMissing) {
      newChangeableFieldProperties[FI].controls[CI].touched = true;
    }
    setChangeableFieldProperties(newChangeableFieldProperties);
  };

  const handleBlur = (FI, CI) => {
    if (changeableFieldProperties[FI].controls[CI].touched) {
      return;
    }

    const newChangeableFieldProperties = { ...changeableFieldProperties };
    if (
      !fieldProperties[FI][CI].required ||
      changeableFieldProperties[FI].controls[CI].originalValue.length > 0
    ) {
      newChangeableFieldProperties[FI].controls[CI].touched = true;
      setChangeableFieldProperties(newChangeableFieldProperties);
      return;
    }

    switch (fieldProperties[FI][CI].name) {
      case "fileUpload":
        if (!changeableFieldProperties[FI].controls[CI].initialTouch) {
          newChangeableFieldProperties[FI].controls[CI].initialTouch = true;
          setChangeableFieldProperties(newChangeableFieldProperties);
          return;
        }
        break;
      case "date":
        if (
          changeableFieldProperties[FI].controls[CI].originalValue?.$d &&
          changeableFieldProperties[FI].controls[CI].originalValue
            ?.$d instanceof Date &&
          !isNaN(
            changeableFieldProperties[FI].controls[
              CI
            ].originalValue?.$d.valueOf()
          )
        ) {
          newChangeableFieldProperties[FI].controls[CI].touched = true;
          setChangeableFieldProperties(newChangeableFieldProperties);
          return;
        }
        break;
      default:
        break;
    }

    newChangeableFieldProperties[FI].controls[CI].touched = true;
    newChangeableFieldProperties[FI].controls[CI].validity.valueMissing = true;
    newChangeableFieldProperties[FI].controls[CI].validity.valid = false;

    newChangeableFieldProperties[FI].controls[CI].error =
      fieldMethods.errorMessage.call(fieldProperties, "valueMissing", FI, CI);
    setChangeableFieldProperties(newChangeableFieldProperties);
  };

  const onSubmit = (FI) => {
    const newChangeableFieldProperties = { ...changeableFieldProperties };
    newChangeableFieldProperties[FI].controls.map((control, CI) => {
      if (control.validity.valid) {
        return;
      }

      if (!control.touched && fieldProperties[FI][CI].required) {
        control.validity.valueMissing = true;
      }

      for (const errorName in control.validity) {
        if (control.validity[errorName]) {
          control.error = fieldMethods.errorMessage.call(
            fieldProperties,
            errorName,
            FI,
            CI
          );
          break;
        }
      }
    });

    newChangeableFieldProperties[FI].isSubmitted = true;
    setChangeableFieldProperties(newChangeableFieldProperties);
  };

  const findFormGroupChildrenArray = (obj) => {
      let controlsArr = data?.map((ele) => ele.controls);
      let filteredControlsArr = controlsArr.map((el) => el.filter((ele) => ele.control_ref === obj?.control_id))
      let filteredControlsArrData = filteredControlsArr.filter((ele) => ele.length > 0)
      let formGroupChildrenArr = filteredControlsArrData[0][0]?.children?.filter((ele) => ele.group_by === obj?.uuid);
      dispatch({
        type: "SET_FORMGROUP_CHILDREN_PREVIEW",
        payload: formGroupChildrenArr,
      });
  }

  const changePreviewFormGroupOption = (obj) => {
    if (Object.keys(selectedFormGroupOptionPreview)?.length > 0) {
      if (selectedFormGroupOptionPreview?.control_id === obj?.control_id) {
        setSelectedFormGroupOptionPreview(obj);
        dispatch({
          type: "SET_ACTIVE_FORMGROUP_PREVIEW",
          payload: obj,
        });
        findFormGroupChildrenArray(obj)
      } else {
        setSelectedFormGroupOptionPreview(obj);
        document.getElementById(activeFormGroupPreview.id).checked = false;
        dispatch({
          type: "SET_ACTIVE_FORMGROUP_PREVIEW",
          payload: obj,
        });
        findFormGroupChildrenArray(obj);
      }
    } else {
        setSelectedFormGroupOptionPreview(obj);
        findFormGroupChildrenArray(obj)
        dispatch({
          type: "SET_ACTIVE_FORMGROUP_PREVIEW",
          payload: obj,
        });
    }
  };

  return (
    <>
      <div className="topheader">
        <div className="container">
          <div className="displayFlex AlignitemCenter justifyContent_spaceBetween">
            <div>
              <img src={MainLogo} alt="" />
            </div>

            <div className="displayFlex AlignitemCenter">
              <div style={{ marginRight: "15px" }}>
                {showInfoTooltip && (
                  <div className="infoPopupleft">
                    Loan already associated with this layout. So, You can't make
                    any changes in this layout
                  </div>
                )}
                {showInfoIcon && (
                  <>
                    <div className="infoiconBlock iconanimate2"></div>
                    <div
                      className="infoiconBlock2"
                      onClick={() => setShowInfoTooltip(!showInfoTooltip)}
                    >
                      <img src={infoIcon} alt="InfoIcon" />
                    </div>
                  </>
                )}
              </div>
              <div className="mr_12">
                <button
                  className="MainButton"
                  onClick={() => {
                    navigate(`/form-builder/${personaId}`);
                  }}
                >
                  Back
                </button>
              </div>
              <div style={defaultLayout ? { opacity: 0.5 } : { opacity: 1 }}>
                <button
                  disabled={!activeChanges || defaultLayout}
                  className={
                    activeChanges ? "MainButton" : "mainButtonDisabled"
                  }
                  onClick={() => html_convertion()}
                >
                  Publish
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt120">
        {/* <h1>Layout Preview!</h1> */}
        {data?.map((control, FI) => {
          return (
            control?.active_flag &&
            control?.no_of_active_controls > 0 && (
              <div key={FI} className="WhiteFrame mb_24">
                <h4 className="heading24 fontWeight_600 textAlignCenter">
                  {control.frame_name}
                </h4>

                <div className="title_border"></div>

                <div className="row">
                  {control.controls?.map((ele, CI) => {
                    return (
                      ele?.active_flag && (
                        <div
                          key={CI}
                          className={
                            ele.properties.type === "textarea" ||
                            ele.properties.name === "paragraph" ||
                            (ele?.properties?.type === "radio" &&
                              ele?.properties?.isFormGroup)
                              ? "col-md-12 mb_24"
                              : "col-md-6 mb_24"
                          }
                        >
                          {ele.properties.name !== "date" &&
                            ele.properties.name !== "banklookup" && (
                              <label
                                className={
                                  ele.properties.type === "file"
                                    ? `biglabelText displayBlock`
                                    : "biglabelText"
                                }
                              >
                                {ele.properties.labelName}{" "}
                                {ele?.properties?.required && (
                                  <span style={{ color: "red" }}>*</span>
                                )}
                              </label>
                            )}

                          {ele.properties.type !== "textarea" &&
                            ele.properties.type !== "checkbox" &&
                            ele.properties.type !== "dropdown" &&
                            ele.properties.type !== "number" &&
                            ele.properties.type !== "radio" &&
                            ele.properties.name !== "date" &&
                            ele.properties.name !== "sign" &&
                            ele.properties.name !== "paragraph" &&
                            ele.properties.name !== "numberstringphone" &&
                            ele.properties.name !== "numberstringssn" &&
                            ele.properties.name !== "banklookup" &&
                            ele.properties.name !== "fileUpload" && (
                              <div className="no-arrows">
                                <input
                                  value={
                                    changeableFieldProperties[FI].controls[CI]
                                      ?.inputFieldValue
                                  }
                                  type={ele.properties.type}
                                  name={ele.properties.name}
                                  className="MainInput"
                                  placeholder={ele.properties.placeholder}
                                  onBlur={() => handleBlur(FI, CI)}
                                  onChange={(e) => handleChange(e, FI, CI)}
                                />

                                {(changeableFieldProperties[FI].isSubmitted ||
                                  changeableFieldProperties[FI].controls[CI]
                                    ?.touched) &&
                                changeableFieldProperties[FI].controls[CI]
                                  ?.error ? (
                                  <p className="errorText">
                                    {
                                      changeableFieldProperties[FI].controls[CI]
                                        .error
                                    }
                                  </p>
                                ) : null}
                              </div>
                            )}
                          {ele.properties.name === "numberstringssn" && (
                            <>
                              <input
                                value={
                                  changeableFieldProperties[FI].controls[CI]
                                    ?.inputFieldValue
                                }
                                type={ele.properties.type}
                                name={ele.properties.name}
                                className="MainInput"
                                placeholder={ele.properties.placeholder}
                                onBlur={() => handleBlur(FI, CI)}
                                onChange={(e) => {
                                  handleChange(e, FI, CI);
                                }}
                              />

                              {(changeableFieldProperties[FI].isSubmitted ||
                                changeableFieldProperties[FI].controls[CI]
                                  ?.touched) &&
                              changeableFieldProperties[FI].controls[CI]
                                ?.error ? (
                                <p className="errorText">
                                  {
                                    changeableFieldProperties[FI].controls[CI]
                                      .error
                                  }
                                </p>
                              ) : null}
                            </>
                          )}

                          {ele.properties.name === "banklookup" && (
                            <>
                              <div>
                                <img src={BankImage} alt="" />
                                <button
                                  style={{ width: "130px" }}
                                  className="MainButton"
                                >
                                  Connect Bank
                                </button>
                              </div>
                            </>
                          )}

                          {ele.properties.name === "sign" && (
                            <>
                              <Signature
                                width={450}
                                height={100}
                                changeableFieldProperties={
                                  changeableFieldProperties
                                }
                                setChangeableFieldProperties={
                                  setChangeableFieldProperties
                                }
                                fieldProperties={fieldProperties}
                                index={{ FI, CI }}
                              />

                              {(changeableFieldProperties[FI].isSubmitted ||
                                changeableFieldProperties[FI].controls[CI]
                                  ?.touched) &&
                              changeableFieldProperties[FI].controls[CI]
                                ?.error ? (
                                <p>
                                  {
                                    changeableFieldProperties[FI].controls[CI]
                                      .error
                                  }
                                </p>
                              ) : null}
                            </>
                          )}

                          {ele.properties.name === "paragraph" && (
                            <p
                              style={{
                                fontSize: `${ele.properties.fontSize}px`,
                                fontWeight: ele.properties.bold
                                  ? "bold"
                                  : "normal",
                                fontStyle: ele.properties.italic
                                  ? "italic"
                                  : "normal",
                              }}
                            >
                              {ele.properties?.paragraph}
                            </p>
                            // <input
                            //   ref={inputCard}
                            //   type={ele.properties.type}
                            //   onChange={handleChange}
                            //   name={ele.properties.name}
                            //   className="MainInput"
                            //   placeholder={ele.properties.placeholder}
                            //   minLength={ele.properties.minLength}
                            // />
                          )}

                          {ele.properties.name === "number" && (
                            <>
                              <input
                                type="text"
                                value={
                                  changeableFieldProperties[FI].controls[CI]
                                    ?.inputFieldValue
                                }
                                name={ele.properties.name}
                                className="MainInput"
                                placeholder={ele.properties.placeholder}
                                onBlur={() => handleBlur(FI, CI)}
                                onChange={(e) => {
                                  handleChange(e, FI, CI);
                                }}
                              />

                              {(changeableFieldProperties[FI].isSubmitted ||
                                changeableFieldProperties[FI].controls[CI]
                                  ?.touched) &&
                              changeableFieldProperties[FI].controls[CI]
                                ?.error ? (
                                <p className="errorText">
                                  {
                                    changeableFieldProperties[FI].controls[CI]
                                      .error
                                  }
                                </p>
                              ) : null}
                            </>
                          )}

                          {ele.properties.name === "numberstringphone" && (
                            <>
                              <input
                                value={
                                  changeableFieldProperties[FI].controls[CI]
                                    ?.inputFieldValue
                                }
                                type={ele.properties.type}
                                onBlur={() => handleBlur(FI, CI)}
                                onChange={(e) => {
                                  handleChange(e, FI, CI);
                                }}
                                name={ele.properties.name}
                                className="MainInput"
                                placeholder={ele.properties.placeholder}
                              />

                              {(changeableFieldProperties[FI].isSubmitted ||
                                changeableFieldProperties[FI].controls[CI]
                                  ?.touched) &&
                              changeableFieldProperties[FI].controls[CI]
                                ?.error ? (
                                <p className="errorText">
                                  {
                                    changeableFieldProperties[FI].controls[CI]
                                      .error
                                  }
                                </p>
                              ) : null}
                            </>
                          )}

                          {ele.properties.type === "checkbox" && (
                            <div>
                              {ele.properties.items?.map((name, index) => {
                                return (
                                  <div key={index} className="tickmark mb_12">
                                    <input
                                      type={ele.properties.type}
                                      id={`${ele.control_ref}_${index}`}
                                      name={ele.control_ref}
                                      onBlur={() => handleBlur(FI, CI)}
                                      onChange={(e) => handleChange(e, FI, CI)}
                                      value={name.value}
                                    />
                                    <label
                                      htmlFor={`${ele.control_ref}_${index}`}
                                    >
                                      {name.key}
                                    </label>
                                  </div>
                                );
                              })}

                              {(changeableFieldProperties[FI].isSubmitted ||
                                changeableFieldProperties[FI].controls[CI]
                                  ?.touched) &&
                              changeableFieldProperties[FI].controls[CI]
                                ?.error ? (
                                <p className="errorText">
                                  {
                                    changeableFieldProperties[FI].controls[CI]
                                      .error
                                  }
                                </p>
                              ) : null}
                            </div>
                          )}

                        <div style={ele.properties?.alignStyle === "horizontal" ? 
                          {display: 'flex', alignItems: "center", gap: '15px', flexWrap: 'wrap'} : {}}
                          >
                            {ele.properties.type === "radio" &&
                              ele.properties.items?.map((radio, index) => {
                                return (
                                  <>
                                    <div
                                      className="prefer_method mb_12"
                                      key={index}
                                    >
                                      <input
                                        type={ele.properties.type}
                                        name={radio?.name ? radio.name : ""}
                                        id={`${ele.control_ref}_${index}`}
                                        onBlur={() => handleBlur(FI, CI)}
                                        onChange={(e) => {
                                          handleChange(e, FI, CI);
                                          ele.properties.type === "radio" && ele.properties.isFormGroup &&
                                            changePreviewFormGroupOption({
                                              control_id: ele?.control_ref,
                                              uuid: radio.id,
                                              type: ele.properties.type,
                                              isFormGroup: ele.properties.isFormGroup,
                                              id: `${ele.control_ref}_${index}`
                                          });
                                        }}
                                        value={radio.value}
                                      />
                                      <label
                                        htmlFor={`${ele.control_ref}_${index}`}
                                      >
                                        {radio.key}
                                      </label>
                                    </div>

                                    {(changeableFieldProperties[FI]
                                      .isSubmitted ||
                                      changeableFieldProperties[FI].controls[CI]
                                        ?.touched) &&
                                    changeableFieldProperties[FI].controls[CI]
                                      ?.error ? (
                                      <p className="errorText">
                                        {
                                          changeableFieldProperties[FI]
                                            .controls[CI].error
                                        }
                                      </p>
                                    ) : null}
                                  </>
                                );
                              })}
                          </div>
                 {/* -------------- FORM GROUP CHILDREN PREVIEW END -------------- */}
                          {ele.properties.type == "radio" &&
                            ele?.properties?.isFormGroup &&
                            Object.keys(selectedFormGroupOptionPreview)?.length > 0 &&
                            selectedFormGroupOptionPreview?.control_id == ele?.control_ref && (
                            <div
                              className="dragAndDrop_frame"
                              style={{
                                marginTop: "15px",
                                minHeight: "150px",
                                height: "auto",
                              }}
                            >
                              <div className="row">
                         {formGroupChildrenPreview?.map((ele, CI) => {
                          return (
                          ele?.active_flag && (
                            <div
                              key={CI}
                              className={
                                ele.properties.type === "textarea" ||
                                ele.properties.name === "paragraph" ||
                                (ele?.properties?.type === "radio" &&
                                  ele?.properties?.isFormGroup)
                                  ? "col-md-12 mb_24"
                                  : "col-md-6 mb_24"
                              }
                            >
                          {ele.properties.name !== "date" &&
                            ele.properties.name !== "banklookup" && (
                              <label
                                className={
                                  ele.properties.type === "file"
                                    ? `biglabelText displayBlock`
                                    : "biglabelText"
                                }
                              >
                                {ele.properties.labelName}{" "}
                                {ele?.properties?.required && (
                                  <span style={{ color: "red" }}>*</span>
                                )}
                              </label>
                            )}

                          {ele.properties.type !== "textarea" &&
                            ele.properties.type !== "checkbox" &&
                            ele.properties.type !== "dropdown" &&
                            ele.properties.type !== "number" &&
                            ele.properties.type !== "radio" &&
                            ele.properties.name !== "date" &&
                            ele.properties.name !== "sign" &&
                            ele.properties.name !== "paragraph" &&
                            ele.properties.name !== "numberstringphone" &&
                            ele.properties.name !== "numberstringssn" &&
                            ele.properties.name !== "banklookup" &&
                            ele.properties.name !== "fileUpload" && (
                              <div className="no-arrows">
                                <input
                                  value={
                                    changeableFieldProperties[FI].controls[CI]
                                      ?.inputFieldValue
                                  }
                                  type={ele.properties.type}
                                  name={ele.properties.name}
                                  className="MainInput"
                                  placeholder={ele.properties.placeholder}
                                  onBlur={() => handleBlur(FI, CI)}
                                  onChange={(e) => handleChange(e, FI, CI)}
                                />

                                {(changeableFieldProperties[FI].isSubmitted ||
                                  changeableFieldProperties[FI].controls[CI]
                                    ?.touched) &&
                                changeableFieldProperties[FI].controls[CI]
                                  ?.error ? (
                                  <p className="errorText">
                                    {
                                      changeableFieldProperties[FI].controls[CI]
                                        .error
                                    }
                                  </p>
                                ) : null}
                              </div>
                            )}
                          {ele.properties.name === "numberstringssn" && (
                            <>
                              <input
                                value={
                                  changeableFieldProperties[FI].controls[CI]
                                    ?.inputFieldValue
                                }
                                type={ele.properties.type}
                                name={ele.properties.name}
                                className="MainInput"
                                placeholder={ele.properties.placeholder}
                                onBlur={() => handleBlur(FI, CI)}
                                onChange={(e) => {
                                  handleChange(e, FI, CI);
                                }}
                              />

                              {(changeableFieldProperties[FI].isSubmitted ||
                                changeableFieldProperties[FI].controls[CI]
                                  ?.touched) &&
                              changeableFieldProperties[FI].controls[CI]
                                ?.error ? (
                                <p className="errorText">
                                  {
                                    changeableFieldProperties[FI].controls[CI]
                                      .error
                                  }
                                </p>
                              ) : null}
                            </>
                          )}

                          {ele.properties.name === "banklookup" && (
                            <>
                              <div>
                                <img src={BankImage} alt="" />
                                <button
                                  style={{ width: "130px" }}
                                  className="MainButton"
                                >
                                  Connect Bank
                                </button>
                              </div>
                            </>
                          )}

                          {ele.properties.name === "sign" && (
                            <>
                              <Signature
                                width={450}
                                height={100}
                                changeableFieldProperties={
                                  changeableFieldProperties
                                }
                                setChangeableFieldProperties={
                                  setChangeableFieldProperties
                                }
                                fieldProperties={fieldProperties}
                                index={{ FI, CI }}
                              />

                              {(changeableFieldProperties[FI].isSubmitted ||
                                changeableFieldProperties[FI].controls[CI]
                                  ?.touched) &&
                              changeableFieldProperties[FI].controls[CI]
                                ?.error ? (
                                <p>
                                  {
                                    changeableFieldProperties[FI].controls[CI]
                                      .error
                                  }
                                </p>
                              ) : null}
                            </>
                          )}

                          {ele.properties.name === "paragraph" && (
                            <p
                              style={{
                                fontSize: `${ele.properties.fontSize}px`,
                                fontWeight: ele.properties.bold
                                  ? "bold"
                                  : "normal",
                                fontStyle: ele.properties.italic
                                  ? "italic"
                                  : "normal",
                              }}
                            >
                              {ele.properties?.paragraph}
                            </p>
                            // <input
                            //   ref={inputCard}
                            //   type={ele.properties.type}
                            //   onChange={handleChange}
                            //   name={ele.properties.name}
                            //   className="MainInput"
                            //   placeholder={ele.properties.placeholder}
                            //   minLength={ele.properties.minLength}
                            // />
                          )}

                          {ele.properties.name === "number" && (
                            <>
                              <input
                                type="text"
                                value={
                                  changeableFieldProperties[FI].controls[CI]
                                    ?.inputFieldValue
                                }
                                name={ele.properties.name}
                                className="MainInput"
                                placeholder={ele.properties.placeholder}
                                onBlur={() => handleBlur(FI, CI)}
                                onChange={(e) => {
                                  handleChange(e, FI, CI);
                                }}
                              />

                              {(changeableFieldProperties[FI].isSubmitted ||
                                changeableFieldProperties[FI].controls[CI]
                                  ?.touched) &&
                              changeableFieldProperties[FI].controls[CI]
                                ?.error ? (
                                <p className="errorText">
                                  {
                                    changeableFieldProperties[FI].controls[CI]
                                      .error
                                  }
                                </p>
                              ) : null}
                            </>
                          )}

                          {ele.properties.name === "numberstringphone" && (
                            <>
                              <input
                                value={
                                  changeableFieldProperties[FI].controls[CI]
                                    ?.inputFieldValue
                                }
                                type={ele.properties.type}
                                onBlur={() => handleBlur(FI, CI)}
                                onChange={(e) => {
                                  handleChange(e, FI, CI);
                                }}
                                name={ele.properties.name}
                                className="MainInput"
                                placeholder={ele.properties.placeholder}
                              />

                              {(changeableFieldProperties[FI].isSubmitted ||
                                changeableFieldProperties[FI].controls[CI]
                                  ?.touched) &&
                              changeableFieldProperties[FI].controls[CI]
                                ?.error ? (
                                <p className="errorText">
                                  {
                                    changeableFieldProperties[FI].controls[CI]
                                      .error
                                  }
                                </p>
                              ) : null}
                            </>
                          )}

                          {ele.properties.type === "checkbox" && (
                            <div>
                              {ele.properties.items?.map((name, index) => {
                                return (
                                  <div key={index} className="tickmark mb_12">
                                    <input
                                      type={ele.properties.type}
                                      id={`${ele.control_ref}_${index}`}
                                      name={ele.control_ref}
                                      onBlur={() => handleBlur(FI, CI)}
                                      onChange={(e) => handleChange(e, FI, CI)}
                                      value={name.value}
                                    />
                                    <label
                                      htmlFor={`${ele.control_ref}_${index}`}
                                    >
                                      {name.key}
                                    </label>
                                  </div>
                                );
                              })}

                              {(changeableFieldProperties[FI].isSubmitted ||
                                changeableFieldProperties[FI].controls[CI]
                                  ?.touched) &&
                              changeableFieldProperties[FI].controls[CI]
                                ?.error ? (
                                <p className="errorText">
                                  {
                                    changeableFieldProperties[FI].controls[CI]
                                      .error
                                  }
                                </p>
                              ) : null}
                            </div>
                          )}

                        <div style={ele.properties?.alignStyle === "horizontal" ? 
                          {display: 'flex', alignItems: "center", gap: '15px', flexWrap: 'wrap'} : {}}
                          >
                            {ele.properties.type === "radio" &&
                              ele.properties.items?.map((radio, index) => {
                                return (
                                  <>
                                    <div
                                      className="prefer_method mb_12"
                                      key={index}
                                    >
                                      <input
                                        type={ele.properties.type}
                                        name={radio?.name ? radio.name : ""}
                                        id={`${ele.control_ref}_${index}`}
                                        onBlur={() => handleBlur(FI, CI)}
                                        onChange={(e) => {
                                          handleChange(e, FI, CI);
                                          ele.properties.type === "radio" && ele.properties.isFormGroup &&
                                            changePreviewFormGroupOption({
                                              control_id: ele?.control_ref,
                                              uuid: radio.id,
                                              type: ele.properties.type,
                                              isFormGroup: ele.properties.isFormGroup,
                                              id: `${ele.control_ref}_${index}`
                                          });
                                        }}
                                        value={radio.value}
                                      />
                                      <label
                                        htmlFor={`${ele.control_ref}_${index}`}
                                      >
                                        {radio.key}
                                      </label>
                                    </div>

                                    {(changeableFieldProperties[FI]
                                      .isSubmitted ||
                                      changeableFieldProperties[FI].controls[CI]
                                        ?.touched) &&
                                    changeableFieldProperties[FI].controls[CI]
                                      ?.error ? (
                                      <p className="errorText">
                                        {
                                          changeableFieldProperties[FI]
                                            .controls[CI].error
                                        }
                                      </p>
                                    ) : null}
                                  </>
                                );
                              })}
                          </div>

                          {ele.properties.type === "date" && (
                            <>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack
                                  spacing={1}
                                  className="customDatepicker-container"
                                >
                                  <label
                                    className="biglabelText customDatapicker-label"
                                    htmlFor={ele.properties.id}
                                  >
                                    {ele.properties.labelName}{" "}
                                    {ele?.properties?.required && (
                                      <span style={{ color: "red" }}>*</span>
                                    )}
                                  </label>
                                  <DesktopDatePicker
                                    className="customDatapicker"
                                    inputFormat={ele.properties.dateFormat}
                                    minDate={dayjs(
                                      setMinDate(ele.properties.dateCondition)
                                    )}
                                    maxDate={dayjs(
                                      setMaxDate(ele.properties.dateCondition)
                                    )}
                                    value={
                                      changeableFieldProperties[FI].controls[CI]
                                        ?.inputFieldValue
                                    }
                                    onChange={(e) => {
                                      handleChange(e, FI, CI);
                                    }}
                                    renderInput={(params) => {
                                      return (
                                        <TextField
                                          {...params}
                                          error={false}
                                          onBlur={() => handleBlur(FI, CI)}
                                        />
                                      );
                                    }}
                                  />
                                </Stack>
                              </LocalizationProvider>

                              {(changeableFieldProperties[FI].isSubmitted ||
                                changeableFieldProperties[FI].controls[CI]
                                  ?.touched) &&
                              changeableFieldProperties[FI].controls[CI]
                                ?.error ? (
                                <p className="errorText">
                                  {
                                    changeableFieldProperties[FI].controls[CI]
                                      .error
                                  }
                                </p>
                              ) : null}
                            </>
                          )}

                          {ele.properties.type === "textarea" && (
                            <>
                              <textarea
                                className="textAreaInput"
                                value={
                                  changeableFieldProperties[FI].controls[CI]
                                    ?.inputFieldValue
                                }
                                type={ele.properties.type}
                                name={ele.properties.name}
                                cols={ele.properties.cols}
                                rows={ele.properties.rows}
                                placeholder={ele.properties.placeholder}
                                onBlur={() => handleBlur(FI, CI)}
                                onChange={(e) => handleChange(e, FI, CI)}
                              />
                              {(changeableFieldProperties[FI].isSubmitted ||
                                changeableFieldProperties[FI].controls[CI]
                                  ?.touched) &&
                              changeableFieldProperties[FI].controls[CI]
                                ?.error ? (
                                <p className="errorText">
                                  {
                                    changeableFieldProperties[FI].controls[CI]
                                      .error
                                  }
                                </p>
                              ) : null}
                            </>
                          )}

                          {ele.properties.type === "dropdown" && (
                            <>
                              <select
                                onBlur={() => handleBlur(FI, CI)}
                                onChange={(e) => handleChange(e, FI, CI)}
                                className="MainDropdown"
                              >
                                <option value="default">
                                  -- Select an Option --
                                </option>
                                {ele.properties.items.map(
                                  (optionName, index) => (
                                    <option
                                      value={optionName.value}
                                      key={index}
                                    >
                                      {optionName.key}
                                    </option>
                                  )
                                )}
                              </select>

                              {(changeableFieldProperties[FI].isSubmitted ||
                                changeableFieldProperties[FI].controls[CI]
                                  ?.touched) &&
                              changeableFieldProperties[FI].controls[CI]
                                ?.error ? (
                                <p className="errorText">
                                  {
                                    changeableFieldProperties[FI].controls[CI]
                                      .error
                                  }
                                </p>
                              ) : null}
                            </>
                          )}

                          {ele.properties.type === "file" && (
                            <>
                              <input
                                type={ele.properties.type}
                                name={ele.properties.name}
                                placeholder={ele.properties.placeholder}
                                accept={ele.properties?.accept}
                                multiple={
                                  ele.properties?.multiple ? true : false
                                }
                                onChange={(e) => handleChange(e, FI, CI)}
                                onBlur={() => handleBlur(FI, CI)}
                              />

                              {(changeableFieldProperties[FI].isSubmitted ||
                                changeableFieldProperties[FI].controls[CI]
                                  ?.touched) &&
                              changeableFieldProperties[FI].controls[CI]
                                ?.error ? (
                                <p className="errorText">
                                  {
                                    changeableFieldProperties[FI].controls[CI]
                                      .error
                                  }
                                </p>
                              ) : null}
                            </>
                          )}
                        </div>
                      )
                    );
                  })}
                              </div>
                            </div>
                          )}
              {/* -------------- FORM GROUP CHILDREN PREVIEW END -------------- */}

                          {ele.properties.type === "date" && (
                            <>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack
                                  spacing={1}
                                  className="customDatepicker-container"
                                >
                                  <label
                                    className="biglabelText customDatapicker-label"
                                    htmlFor={ele.properties.id}
                                  >
                                    {ele.properties.labelName}{" "}
                                    {ele?.properties?.required && (
                                      <span style={{ color: "red" }}>*</span>
                                    )}
                                  </label>
                                  <DesktopDatePicker
                                    className="customDatapicker"
                                    inputFormat={ele.properties.dateFormat}
                                    minDate={dayjs(
                                      setMinDate(ele.properties.dateCondition)
                                    )}
                                    maxDate={dayjs(
                                      setMaxDate(ele.properties.dateCondition)
                                    )}
                                    value={
                                      changeableFieldProperties[FI].controls[CI]
                                        ?.inputFieldValue
                                    }
                                    onChange={(e) => {
                                      handleChange(e, FI, CI);
                                    }}
                                    renderInput={(params) => {
                                      return (
                                        <TextField
                                          {...params}
                                          error={false}
                                          onBlur={() => handleBlur(FI, CI)}
                                        />
                                      );
                                    }}
                                  />
                                </Stack>
                              </LocalizationProvider>

                              {(changeableFieldProperties[FI].isSubmitted ||
                                changeableFieldProperties[FI].controls[CI]
                                  ?.touched) &&
                              changeableFieldProperties[FI].controls[CI]
                                ?.error ? (
                                <p className="errorText">
                                  {
                                    changeableFieldProperties[FI].controls[CI]
                                      .error
                                  }
                                </p>
                              ) : null}
                            </>
                          )}

                          {ele.properties.type === "textarea" && (
                            <>
                              <textarea
                                className="textAreaInput"
                                value={
                                  changeableFieldProperties[FI].controls[CI]
                                    ?.inputFieldValue
                                }
                                type={ele.properties.type}
                                name={ele.properties.name}
                                cols={ele.properties.cols}
                                rows={ele.properties.rows}
                                placeholder={ele.properties.placeholder}
                                onBlur={() => handleBlur(FI, CI)}
                                onChange={(e) => handleChange(e, FI, CI)}
                              />
                              {(changeableFieldProperties[FI].isSubmitted ||
                                changeableFieldProperties[FI].controls[CI]
                                  ?.touched) &&
                              changeableFieldProperties[FI].controls[CI]
                                ?.error ? (
                                <p className="errorText">
                                  {
                                    changeableFieldProperties[FI].controls[CI]
                                      .error
                                  }
                                </p>
                              ) : null}
                            </>
                          )}

                          {ele.properties.type === "dropdown" && (
                            <>
                              <select
                                onBlur={() => handleBlur(FI, CI)}
                                onChange={(e) => handleChange(e, FI, CI)}
                                className="MainDropdown"
                              >
                                <option value="default">
                                  -- Select an Option --
                                </option>
                                {ele.properties.items.map(
                                  (optionName, index) => (
                                    <option
                                      value={optionName.value}
                                      key={index}
                                    >
                                      {optionName.key}
                                    </option>
                                  )
                                )}
                              </select>

                              {(changeableFieldProperties[FI].isSubmitted ||
                                changeableFieldProperties[FI].controls[CI]
                                  ?.touched) &&
                              changeableFieldProperties[FI].controls[CI]
                                ?.error ? (
                                <p className="errorText">
                                  {
                                    changeableFieldProperties[FI].controls[CI]
                                      .error
                                  }
                                </p>
                              ) : null}
                            </>
                          )}

                          {ele.properties.type === "file" && (
                            <>
                              <input
                                type={ele.properties.type}
                                name={ele.properties.name}
                                placeholder={ele.properties.placeholder}
                                accept={ele.properties?.accept}
                                multiple={
                                  ele.properties?.multiple ? true : false
                                }
                                onChange={(e) => handleChange(e, FI, CI)}
                                onBlur={() => handleBlur(FI, CI)}
                              />

                              {(changeableFieldProperties[FI].isSubmitted ||
                                changeableFieldProperties[FI].controls[CI]
                                  ?.touched) &&
                              changeableFieldProperties[FI].controls[CI]
                                ?.error ? (
                                <p className="errorText">
                                  {
                                    changeableFieldProperties[FI].controls[CI]
                                      .error
                                  }
                                </p>
                              ) : null}
                            </>
                          )}
                        </div>
                      )
                    );
                  })}
                </div>
                <div
                  className="displayFlex"
                  style={{ width: "100px", gap: "10px" }}
                >
                  <button onClick={() => onSubmit(FI)} className="MainButton">
                    Continue
                  </button>
                  {control?.skip_frame && (
                    <button className="MainButton">Skip</button>
                  )}
                </div>
              </div>
            )
          );
        })}
      </div>
    </>
  );
};

/*
FI -> Frame Index
CI -> Control Index 
*/

export default Preview;
