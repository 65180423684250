import httpHandler from "../_common/httpHandler";

export const geturl = (url) => {
  return `${process.env.REACT_APP_APIURL}/${url}`;
};

export const Get = async (url) => {
  let activeUrl = geturl(url);
  let data = await httpHandler.get(activeUrl, "", {});
  return data;
};

export const Post = async (url, body) => {
  let activeUrl = geturl(url);
  let data = await httpHandler.post(activeUrl, body, {});
  return data;
};

export const Put = async (url, body) => {
  let activeUrl = geturl(url);
  let data = await httpHandler.put(activeUrl, body);
  return data;
};

export const Patch = async (url, body) => {
  let activeUrl = geturl(url);
  let data = await httpHandler.patch(activeUrl, body);
  return data;
};

export const Delete = async (url) => {
  let activeUrl = geturl(url);
  let data = await httpHandler.delete(activeUrl, {});
  return data;
};
