import React, { useEffect, useState } from "react";
import thankyou from "../assets/images/thankyou_img.jpg";
// import DeployingLoader from "../assets/images/deploying_loader.svg";
import { Get } from "../_common/httpService";
import { useNavigate } from "react-router-dom";

const Thankyou = () => {
  const isPub = JSON.parse(sessionStorage.getItem("isPublishing"));
  const lref = JSON.parse(sessionStorage.getItem("lref"));
  const personaId = sessionStorage.getItem("personaId");
  const [pubStatus, setPubStatus] = useState("");
  const navigate = useNavigate();

  const getPublishingStatus = async () => {
    try {
      let resp = await Get(`layout/publishstatus/${lref}`);
      if (resp) {
        setPubStatus(resp.data[0].publish_status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const navToHome = () => {
    setTimeout(() => {
      navigate(`/form-builder/${personaId}`);
    }, 30000);
  };

  useEffect(() => {
    navToHome();
    getPublishingStatus();
  }, []);

  return (
    <>
      <div className="thankyousection">
        {isPub === false && pubStatus === "build_inprogress" ? (
          <div className="thankyoublock">
            <div className="textAlignCenter">
              <img
                src={thankyou}
                alt="thankyouIcon"
                style={{ width: "100%" }}
              />

              <div className="fontsize30"> Thank You </div>
              <div className="fontsize20">
                Your layout is getting ready. Once it gets completed, you will
                receive an email
              </div>
            </div>
          </div>
        ) : isPub === false && pubStatus === "build_failed" ? (
          <div className="thankyoublock">
            <div className="textAlignCenter">
              <div className="fontsize30">
                {" "}
                Build Failed. <br /> Something went wrong!{" "}
              </div>
            </div>
          </div>
        ) : (
          <div className="thankyoublock">
            <div className="textAlignCenter">
              <div className="fontsize30">
                Your changes are deploying. <br /> Please wait ...
              </div>
            </div>
          </div>
        )}
      </div>

      {/* <div style={sty}>

        <h2>Thankyou</h2>
      </div> */}
    </>
  );
};

export default Thankyou;
