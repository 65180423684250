import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import reducer, { initialState } from "./StateProvider/Reducer";
import { StateProvider } from "./StateProvider/StateProvider";

// Axios.interceptors.request.use(
//   function (config) {
//     let a = config.url;

//     let checkUrl =
//       a.includes(`controls/create`) || a.includes(`controls/delete`);
//     if (!checkUrl) {
//       var div1 = document.createElement("div");
//       div1.setAttribute("class", "loderBlock");
//       div1.setAttribute("id", "Loader");
//       var div2 = document.createElement("div");

//       div1.appendChild(div2);
//       document.body.appendChild(div1);
//     }
//     return config;
//   },
//   function (error) {
//     const element = document.getElementById("Loader");
//     element?.remove();
//     return Promise.reject(error);
//   }
// );

// Axios.interceptors.response.use(
//   function (response) {
//     // spinning hide
//     const element = document.getElementById("Loader");
//     element?.remove();

//     return response;
//   },
//   function (error) {
//     const element = document.getElementById("Loader");
//     element?.remove();

//     return Promise.reject(error);
//   }
// );

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <StateProvider initialState={initialState} reducer={reducer}>
    <App />
  </StateProvider>
  // </React.StrictMode>,
);
